import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/rifas.css'

import Toast from '../components/toast/Toast';

import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddIcon from '@mui/icons-material/Add';

import { ReactComponent as CaixaIcon } from '../assets/imgs/icons/caixa.svg';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import MyTable from '../components/mytable/MyTable';

import ModelDetalhesRifa from '../components/modals/modalDetalhesRifa/ModalDetalhesRifa';
import ModalResultados from '../components/modals/modalResultados/ModalResultados';
import ModalRifaEditar from '../components/modals/modalRifaEditar/ModalRifaEditar';
import ModalRifaCriar from '../components/modals/modalRifaCriar/ModalRifaCriar';

import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';

import { mask_phone, mask_cpf } from '../utils/masks';  
import { ArrowBackIos } from '@mui/icons-material';

function Rifas() {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);
    const [replay, setReplay] = useState(0);

    const [rifas, setRifas] = useState([]);
    const [resultadosRifa, setResultadosRifa] = useState(false);
    const [editarRifa, setEditarRifa] = useState(false);
    const [criarRifa, setCriarRifa] = useState(false);

    useEffect(() => {

        setLoading(true);

        usuarioLogado?.id && api.rifas.getByEmpresa(usuarioLogado?.id).then(response => {
            
            if (response.error) {
                return;
            }

            setRifas(response);

            setLoading(false);

        });

    }, [usuarioLogado?.id, replay])

    const columns = [
        { Header: 'Nome', accessor: 'nome', Cell: ({row}) => <span title={row.original.nome}>{row.original.nome?.slice(0, 20)+'...'}</span>},
        { Header: 'Descrição', accessor: 'describe', Cell: ({row}) => <span title={row.original.describe}>{row.original.describe?.slice(0, 20)+'...'}</span>},
        { Header: 'Quantidade', accessor: (row) => (row.sonho ? row.sonho : row.quant).toLocaleString('pt-br')},
        { Header: 'Valor', accessor: (row) => row.valor.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) },
        { Header: 'Criada em', accessor: (row) => new Date(row.created_at).toLocaleDateString() },
        { Header: 'Data do sorteio', accessor: (row) => !isNaN((new Date(row.expire_at)).getDate()) ? (new Date(row.expire_at)).toLocaleDateString() : 'Não informada' },
        { Header: 'Mínimo', accessor: 'min'},
        { Header: 'Máximo', accessor: (row) => row.max ? row.max : 'Ilimitado'},
        { Header: 'Elástica', accessor: (row) => row.elastica ? 'Sim' : 'Não'},
        { Header: 'Fidelidade', accessor: (row) => row.fidelidade ? 'Sim' : 'Não'},
        { Header: 'Ativo', accessor: (row) => row.active ? 'Sim' : 'Não'},
        { Header: 'Finalizada', accessor: (row) => row.finalizada ? 'Sim' : 'Não'},
        { Header: 'Ações', accessor: (row) => row, Cell: ({row}) => {

            let dados = row.original;

            return (
                <div class='d-flex'>
                    <Link to={'/rifa/'+dados.id} class='btn btn-dark d-flex mx-1 my-1' title='Detalhes'><ConfirmationNumberOutlinedIcon/></Link>
                    <button class='btn btn-dark d-flex mx-1 my-1' title="Editar" onClick={() => setEditarRifa(dados.id)}><ModeEditIcon/></button>
                    <button class='btn btn-dark d-flex mx-1 my-1' title="Resultados da Loteria Federal" onClick={() => setResultadosRifa(dados)}>
                        <CaixaIcon className='MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-i4bv87-MuiSvgIcon-root'/>
                    </button>
                </div>
            )
        }}

    ];

    return(
        <div className='container mt-4'>

            <section className="">

                <div class='mb-4'>

                    {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

                    <h3>Administração de Rifas</h3>
                    <p>Veja as rifas cadastradas e gerencie-as</p>
                </div>

                <div class='d-flex justify-content-between mb-4'>
                    <button class='btn btn-success d-flex pe-4' onClick={() => setCriarRifa(true)}><AddIcon className='me-1'/> Criar</button>
                </div>

                <div class='position-relative'>
                    <MyTable columns={columns} data={rifas} />
                    <LoadingSplash active={loading} absolute/>
                </div>

            </section>

            <ModalResultados show={resultadosRifa ? true : false} rifa={resultadosRifa} onHide={() => setResultadosRifa(false)}/>
            <ModalRifaEditar show={editarRifa ? true : false} rifa={editarRifa} onHide={() => {setEditarRifa(false); setReplay(replay+1)}}/>
            <ModalRifaCriar show={criarRifa ? true : false} onHide={() => {setCriarRifa(false); setReplay(replay+1)}}/>
        </div>
    )
}

export default Rifas