function formatDate(format, date=new Date()) {
    const map = {
        mm: String(date.getMonth() + 1).padStart(2, '0'),
        dd: String(date.getDate()).padStart(2, '0'),
        yy: String(date.getFullYear()).slice(-2),
        yyyy: String(date.getFullYear()),
        HH: String(date.getHours()).padStart(2, '0'),
        MM: String(date.getMinutes()).padStart(2, '0'),
        SS: String(date.getSeconds()).padStart(2, '0'),
    }

    return format.replace(/yyyy|yy|mm|dd|HH|MM|SS/gi, matched => map[matched]);
}

export default formatDate;