import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/remarketing.css'

import Toast from '../components/toast/Toast';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Instagram, EmailOutlined, ArrowBackIos } from '@mui/icons-material';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import MyTable from '../components/mytable/MyTable';

import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';

import { mask_phone, mask_cpf } from '../utils/masks';  

function UsersNotBuy() {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [rifas, setRifas] = useState([]);
    const [selectedRifa, setSelectedRifa] = useState(null);

    const today = new Date();
    var today_str = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2,'0')}-${today.getDate().toString().padStart(2,'0')}`

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    var yesterday_str = `${yesterday.getFullYear()}-${(yesterday.getMonth() + 1).toString().padStart(2,'0')}-${yesterday.getDate().toString().padStart(2,'0')}`

    const [periodos, setPeriodos] = useState({inicio: yesterday_str, fim: today_str});

    useEffect(() => {

        setLoading(true);

        api.rifas.getByEmpresa(usuarioLogado?.id).then(response => {
            
            if (response.error) {
                return;
            }

            setRifas(response);

        });

        api.cliente.getNotBuy(selectedRifa, periodos.inicio, periodos.fim).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setData(response);

            setLoading(false);

        })

    }, [periodos, selectedRifa, usuarioLogado?.id])

    const columns = [
        { Header: 'ID', accessor: (row) => '#'+row.id },
        { Header: 'Nome', accessor: 'nome' },
        { Header: 'Celular', accessor: (row) => mask_phone(row.celular) },
        { Header: 'Email', accessor: 'email' },
        { Header: 'CPF', accessor: (row) => mask_cpf(row.cpf)},
        { Header: 'Data de criação', accessor: (row) => new Date(row.created_at).toLocaleString() },
        { Header: 'Ações', accessor: (row) => row, Cell: ({row}) => {

            let dados = row.original;

            return (
                <>
                    <Link target='_blank' to={`https://api.whatsapp.com/send/?phone=55${dados.celular}`} className='btn btn-success m-1' title='Enviar mensagem'><WhatsAppIcon/></Link>
                    {dados.instagram && <Link target='_blank' to={`https://www.instagram.com/${dados.instagram}`} className='btn m-1 insta-btn' title='Ver perfil'><Instagram/></Link>}
                    {dados.email && <Link to={`mailto:${dados.email}`} className='btn btn-danger m-1' title='Enviar e-mail'><EmailOutlined/></Link>}
                </>
            )
        }}

    ];

    return(
        <div className='container mt-4'>

            <section className="">

                <div class='mb-4'>

                    {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

                    <h3>Usúarios sem nenhum pagamento</h3>
                    <p>Lista de pessoas que tem um cadastro na plataforma mas não iniciaram uma compra</p>
                </div>

                <div className="row mt-2 mb-2">
                    <div className="col">
                        <label>Rifas:</label>
                        <select className="form-control cursor" onChange={e => setSelectedRifa(e.target.value)}>
                            <option value=''>Todas</option>
                            {rifas && rifas.map(rifa => <option value={rifa.id}>{rifa.nome}</option>)}
                        </select>
                    </div>
                </div>

                <div className="row my-4">
                    <div className="col">
                        <label>De</label>
                        <input type='date' className="form-control" value={periodos.inicio} onChange={e => setPeriodos({...periodos, inicio: e.target.value})}></input>
                    </div>
                    <div className="col">
                        <label>Até</label>
                        <input type='date' className="form-control" value={periodos.fim} onChange={e => setPeriodos({...periodos, fim: e.target.value})}></input>
                    </div>
                </div>

                <div class='position-relative'>
                    <MyTable columns={columns} data={data} />
                    <LoadingSplash active={loading} absolute/>
                </div>

            </section>

        </div>
    )
}

export default UsersNotBuy