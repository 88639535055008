import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './menulogin.css';

import SemLogo from '../../assets/imgs/menu/semlogo.png';
import LogoRifahit from '../../assets/imgs/logos/3.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ModalCadastro from '../modals/modalCadastro/ModalCadastro';

function MenuLogin(){
    const [imagem, setImagem] = useState(LogoRifahit);
    const [menu, setMenu] = useState(false);
    const [modalCadastro, setModalCadastro] = useState(false);

    useEffect(() => {

    }, []);

    return(
        <header>
            <Link to='/' className="logo"><img src={imagem} alt="Logo" class="sem-logo"/></Link>
            <ul class="navigation w-100 ps-sm-5 pe-sm-5">
                {/* <li><input type='text' placeholder='Search'></input></li> */}
                <li class='btn btn-outline-success ms-auto me-sm-3'>Acessar</li>
                <li class='btn btn-success' onClick={() => setModalCadastro(true)}>Criar conta</li>
            </ul>
            {!menu ? <MenuIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></MenuIcon>
            :
            <CloseIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></CloseIcon>}

            <ModalCadastro show={modalCadastro} onHide={() => setModalCadastro(false)}></ModalCadastro>
        </header>
    )
}

export default MenuLogin