import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import Toast from '../../components/toast/Toast';
import { ArrowBackIos, Check, ThumbDownAltOutlined, InfoOutlined } from '@mui/icons-material';

import LoadingSplash from '../../components/LoadingSplash/LoadingSplash';
import MyTable from '../../components/mytable/MyTable';

import { api } from '../../apis/connect';
import { useAuth } from '../../contexts/AuthContext';

import { mask_phone, mask_cpf, mask_money } from '../../utils/masks';  
import Swal from 'sweetalert2';

function Saques() {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    // const [inputSearch, setInputSearch] = useState('');

    const [replay, setReplay] = useState(0);

    // const today = new Date();
    // var today_str = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2,'0')}-${today.getDate().toString().padStart(2,'0')}`

    // const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() - 1);
    // var yesterday_str = `${yesterday.getFullYear()}-${(yesterday.getMonth() + 1).toString().padStart(2,'0')}-${yesterday.getDate().toString().padStart(2,'0')}`

    // const [periodos, setPeriodos] = useState({inicio: yesterday_str, fim: today_str});

    useEffect(() => {

        setLoading(true);

        api.afiliados.saques.search(search).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setData(response);

            setLoading(false);

        })

    }, [search, replay]);

    function handlerAprovar(id) {
        
        Swal.fire({
            title: 'Aprovar saque',
            text: 'Deseja realmente aprovar este saque?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#198754',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                api.afiliados.saques.aprova(id).then(response => {
                    if (response.error) {
                        Toast.error(response.error);
                        return;
                    }
                    Toast.success('Saque aprovado com sucesso');
                    setReplay(replay + 1);
                })
            }
        })

    }

    function handlerNegar(id) {

        Swal.fire({
            title: 'Deseja realmente reprovar este saque?',
            input: 'text',
            inputLabel: 'Motivo:',
            inputPlaceholder: 'Digite o motivo da negação',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            confirmButtonColor: '#198754',
            cancelButtonText: 'Não'
        }).then((result) => {
            if (result.isConfirmed) {
                api.afiliados.saques.reprova(id, result.value).then(response => {
                    if (response.error) {
                        Toast.error(response.error);
                        return;
                    }
                    Toast.success('Saque reprovado com sucesso');
                    setReplay(replay + 1);
                })
            }
        })

    }

    const columns = [
        { Header: 'ID', accessor: (row) => '#'+row.id },
        { Header: 'Nome', accessor: 'nome' },
        { Header: 'Valor', accessor: (row) => mask_money(row.valor) },
        { Header: 'PIX (CPF)', accessor: (row) => mask_cpf(row.cpf) },
        { Header: 'Pago', accessor: (row) => row.paid ? 'Sim' : 'Não' },
        { Header: 'Data de pagamento', accessor: (row) => row.paid_at ? new Date(row.paid_at).toLocaleString() : 'Não pago' },
        { Header: 'Data de criação', accessor: (row) => new Date(row.created_at).toLocaleString() },
        { Header: 'Cancelado', accessor: (row) => row.cancelado ? <span class="" title={row.motivo}>Sim <InfoOutlined className='ms-1' fontSize='small'/></span> : 'Não' },
        { Header: 'Ações', accessor: (row) => row, Cell: ({row}) => {

            let dados = row.original;

            return (
                <>
                    {(!dados.paid && !dados.cancelado) && <button className='btn btn-success m-1' onClick={() => handlerAprovar(dados.id)} title='Aprovar'><Check/></button>}
                    {(!dados.paid && !dados.cancelado) && <button className='btn btn-danger m-1' onClick={() => handlerNegar(dados.id)} title='Negar'><ThumbDownAltOutlined/></button>}
                </>
            )
        }}

    ];

    return(
        <div className='container mt-4'>

            <section className="">

                {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

                <div class='mb-4'>
                    <h3>Solicitações de saque</h3>
                    <p>Lista de solicitações de saque para aprovação</p>
                </div>

                {/* <div className="row my-4">

                    <div className="col-6">
                        <label>De</label>
                        <input type='date' className="form-control" value={periodos.inicio} onChange={e => setPeriodos({...periodos, inicio: e.target.value})}></input>
                    </div>
                    <div className="col-6">
                        <label>Até</label>
                        <input type='date' className="form-control" value={periodos.fim} onChange={e => setPeriodos({...periodos, fim: e.target.value})}></input>
                    </div>

                    <div className="col-6">
                        <div class='ms-1 mb-1'>Pesquisar :</div>
                        <div class='d-flex align-itens-center'>
                            <input type='text' className="form-control" placeholder='Nome, Celular, E-mail, Instagram, CPF...' value={inputSearch} onChange={e => setInputSearch(e.target.value)}></input>
                            <button className='btn btn-dark ms-2' onClick={() => setSearch(inputSearch)}><Search/></button>
                        </div>
                    </div>

                </div> */}

                <div class='position-relative'>
                    <MyTable columns={columns} data={data} />
                    <LoadingSplash active={loading} absolute/>
                </div>

            </section>

        </div>
    )
}

export default Saques;