import React, {useState, useRef} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function ModalDefault(props) {

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                {props.children}

            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

        </Modal>
    );
}


export default ModalDefault;
