import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Toast from '../../toast/Toast';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import { api } from '../../../apis/connect';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

function ModalRifaBotoes(props) {

    const [replay, setReplay] = useState(0);

    const [botoes, setBotoes] = useState([]);

    const [quantidade, setQuantidade] = useState(1);
    const [isDestaque, setIsDestaque] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        props?.rifa?.id && api.rifas.botoes.get(props?.rifa?.id).then(response => {

            if (response.error) {
                setLoading(false);
                setBotoes([]);
                return;
            }

            setBotoes(response);

            setLoading(false);

        });

    }, [props?.rifa?.id, replay]);

    let handlerAdd = () => {

        if (!quantidade) {
            Toast.warning('Campo quantidade é obrigatório');
            return;
        }

        api.rifas.botoes.create(props?.rifa?.id, quantidade, isDestaque).then(response => {
                
            if (response.error) {
                Toast.warning(response.error);
                setLoading(false);
                return;
            }

            Toast.success(`Botão criado com sucesso`);

            setReplay(replay+1);
            setIsDestaque(false);

        });

    }

    let handlerRemove = (botao) => {
            
            setLoading(true);
    
            api.rifas.botoes.remove(botao).then(response => {
    
                if (response.error) {
                    Toast.error(response.error);
                    return;
                }
    
                Toast.success('Botão removido com sucesso');
    
                setReplay(replay+1);
    
                setLoading(false);
    
            });
    }

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rifa #{props?.rifa?.id} - Botões de compra
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h5 class='d-block mb-2'>Botões ativos:</h5>
                <div class='d-flex justify-content-center my-5 flex-wrap'>

                    {botoes && botoes.length > 0 ? botoes.map((botao, index) => (
                        <button key={index} className={`btn btn-outline-success m-2 d-inline-flex flex-column justify-content-center position-relative rh-numbers`}>
                            <b class='mx-auto'>+{botao.numbers}</b>
                            {botao.is_destaque !== 0 && <div class='mx-auto'>Mais popular</div>}
                            <div class='rh-btn-remove d-none' onClick={() => handlerRemove(botao.id)}><ClearIcon/></div>
                        </button>
                    )) : 'Nenhum botão ativo'}
                </div>

                <h5 class='d-block my-3'>Adicionar botão:</h5>

                <div class='row'>

                    <div class='col-6 mb-2'>
                        <label class='d-block mb-2'>Quantidade de números:</label>
                        <input 
                            class='form-control mb-2' 
                            placeholder='000000' 
                            value={quantidade} 
                            onChange={(e) => setQuantidade(e.target.value)} 
                            type='number' 
                            min='1'>
                        </input>
                    </div>

                    <div class='col-6 mb-2'>
                        <label class='d-block mb-2'>Marcar como "Mais popular" ?</label>
                        <div class='form-check form-switch'>
                            <input type="checkbox" role="switch" class='form-check-input' checked={isDestaque} onChange={(e) => setIsDestaque(e.target.checked)}/>
                        </div>
                    </div>

                    <div class='col-12'>
                        <button class='btn btn-dark d-flex' onClick={handlerAdd}><AddIcon className='me-1'/>Adicionar</button>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            <LoadingSplash active={loading} absolute/>

        </Modal>
    );
}


export default ModalRifaBotoes;
