import md5 from "md5";

const url = process.env.REACT_APP_API;
const url_storage = process.env.REACT_APP_STORAGE;

let resgataToken = () => {return localStorage.getItem('token')}

const api = {

    login: async (email, password) => {
        const response = await fetch(`${url}/login/access`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email,
                password,
            })
        });
        return response.json();
    },

    cadastrar: async (nome, email, telefone, password) => {
        const response = await fetch(`${url}/empresas/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                nome: nome,
                email: email,
                telefone: telefone,
                password: password
            })
        });
        return response.json();
    },

    cliente: {

        exists: async (celular, empresa) => {
            const response = await fetch(`${url}/clientes/exists`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    empresa,
                    celular
                })
            });
            return response.json();
        
        },

        getNotBuy: async (rifa, inicio, fim) => {
            const response = await fetch(`${url}/clientes/getNotBuy`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    rifa,
                    inicio,
                    fim
                })
            });
            return response.json();
        },

        search: async (search) => {
            const response = await fetch(`${url}/clientes/search`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    search
                })
            });
            return response.json();
        },

        get: async (id) => {
            const response = await fetch(`${url}/clientes/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    id
                })
            });
            return response.json();
        },

        create: async (nome, email, celular, cpf, empresa) => {
            const response = await fetch(`${url}/clientes/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    nome,
                    email,
                    celular,
                    cpf,
                    empresa
                })
            });
            return response.json();
        },

        update: async (cliente) => {
            const response = await fetch(`${url}/clientes/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    ...cliente
                })
            });
            return response.json();
        }

    },

    afiliados: {

        create: async (afiliado) => {
            const response = await fetch(`${url}/afiliados/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    ...afiliado,
                    password: md5(afiliado?.password)
                })
            });
            return response.json();
        },

        search: async (search) => {
            const response = await fetch(`${url}/afiliados/getafiliados`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    search
                })
            });
            return response.json();
        },

        get: async (id) => {
            const response = await fetch(`${url}/afiliados/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    afiliado: id
                })
            });
            return response.json();
        },

        update: async (afiliado) => {
            const response = await fetch(`${url}/afiliados/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    ...afiliado,
                    afiliado: afiliado?.id
                })
            });
            return response.json();
        },

        block: async (afiliado, status) => {
            const response = await fetch(`${url}/afiliados/block`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    afiliado,
                    status
                })
            });
            return response.json();
        },

        updatePorcentagem: async (afiliado, porcentagem) => {
            const response = await fetch(`${url}/afiliados/updatePorcentagem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    afiliado,
                    porcentagem
                })
            });
            return response.json();
        },

        updatePassword: async (afiliado, password) => {
            const response = await fetch(`${url}/afiliados/updatePassword`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    afiliado,
                    password: md5(password)
                })
            });
            return response.json();
        },

        saques: {

            search: async (search) => {
                const response = await fetch(`${url}/afiliados/getSaquesEmpresa`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        search
                    })
                });
                return response.json();
            },

            get: async (id) => {
                const response = await fetch(`${url}/afiliados/getSaque`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        saque: id
                    })
                });
                return response.json();
            },

            aprova: async (saque) => {
                const response = await fetch(`${url}/afiliados/aprovaSaque`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        saque
                    })
                });
                return response.json();
            },

            reprova: async (saque, motivo) => {
                const response = await fetch(`${url}/afiliados/reprovaSaque`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        saque,
                        motivo
                    })
                });
                return response.json();
            }

        }

    },

    empresa: {

        get: async (empresa) => {
            const response = await fetch(`${url}/empresas/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    empresa: empresa
                })
            });
            return response.json();
        },

        updateAfiliadoPorcentagem: async (porcentagem) => {
            const response = await fetch(`${url}/empresas/updateAfiliadoPorcentagem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    porcentagem
                })
            });
            return response.json();
        }

    },

    pagamentos: {

        // get: async (empresa) => {
        //     const response = await fetch(`${url}/pagamentos/get`, {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'X-Authorization': `Bearer ${resgataToken()}`
        //         },
        //         body: JSON.stringify({
        //             empresa: empresa
        //         })
        //     });
        //     return response.json();
        // },

        getIncompletos: async (rifa, inicio, fim) => {
            const response = await fetch(`${url}/pagamentos/getIncompletos`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    rifa,
                    inicio,
                    fim
                })
            });
            return response.json();
        },

        getByEmpresa: async (inicio, fim, rifa) => {
            const response = await fetch(`${url}/pagamentos/getByEmpresa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    inicio,
                    fim,
                    rifa
                })
            });
            return response.json();
        },

        getByCliente: async (cliente, inicio, fim, rifa) => {
            const response = await fetch(`${url}/pagamentos/getByCliente`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    cliente,
                    inicio,
                    fim,
                    rifa
                })
            });
            return response.json();
        },

        cortesia: async (cliente, rifa, quantidade, motivo) => {
            const response = await fetch(`${url}/pagamentos/createbyempresa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    rifa,
                    quantidade,
                    motivo,
                    empresa: cliente.empresas_id,
                    nome: cliente?.nome,
                    celular: cliente?.celular,
                    cpf: cliente?.cpf,
                    email: cliente?.email
                })
            });
            return response.json();
        }

    },

    rifas: {

        create: async (rifa) => {
            const response = await fetch(`${url}/rifas/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    ...rifa,
                    elastica: rifa.elastica == "1" ? true : false,
                    ranking: rifa.ranking == "1" ? true : false,
                    max: rifa.max === 0 ? null : rifa.max,
                    valor: (typeof rifa.valor) === 'number' ? rifa.valor : parseFloat(String(rifa.valor).replace('R$ ', '').replace('.', '').replace(',', '.')),
                })
            });
            return response.json();
        },

        update: async (rifa) => {
            const response = await fetch(`${url}/rifas/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    ...rifa,
                    active: rifa.active == "1" ? true : false,
                    elastica: rifa.elastica == "1" ? true : false,
                    fidelidade: rifa.fidelidade == "1" ? true : false,
                    ranking: rifa.ranking == "1" ? true : false,
                    max: rifa.max === 0 ? null : rifa.max,
                    valor: (typeof rifa.valor) === 'number' ? rifa.valor : parseFloat(String(rifa.valor).replace('R$ ', '').replace('.', '').replace(',', '.')),
                })
            });
            return response.json();
        },

        getByEmpresa: async (empresa) => {
            const response = await fetch(`${url}/rifas/getByEmpresa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    empresa
                })
            });
            return response.json();
        },

        get: async (rifa) => {
            const response = await fetch(`${url}/rifas/get`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    id: rifa
                })
            });
            return response.json();
        },

        getPlus: async (rifa) => {
            const response = await fetch(`${url}/rifas/getPlus`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    id: rifa
                })
            });
            return response.json();
        },

        getNumero: async (rifa, numero) => {
            const response = await fetch(`${url}/rifas/getNumero`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    rifa,
                    numero
                })
            });
            return response.json();
        },

        slug: async (rifa, slug) => {
            const response = await fetch(`${url}/rifas/updateSlug`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    rifa,
                    slug
                })
            });
            return response.json();
        },

        imagens: {

            get: async (rifa) => {
                const response = await fetch(`${url}/rifas/getFotos`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa
                    })
                });
                return response.json();
            },

            add: async (foto) => {
                const response = await fetch(`${url}/rifas/addFoto`, {
                    method: 'POST',
                    headers: {
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: foto
                });
                return response.json();
            },

            remove: async (foto) => {
                const response = await fetch(`${url}/rifas/removeFoto`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        id: foto
                    })
                });
                return response.json();
            }

        },

        numerosPremiados: {

            get: async (rifa) => {
                const response = await fetch(`${url}/rifas/getNumerosPremiados`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa
                    })
                });
                return response.json();
            },

            set: async (rifa, numeros) => {
                const response = await fetch(`${url}/rifas/setNumerosPremiados`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        ...numeros
                    })
                });
                return response.json();
            },

            remove : async (rifa, numeros) => {
                const response = await fetch(`${url}/rifas/removeNumerosPremiados`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        numeros
                    })
                });
                return response.json();
            }

        },

        promocoes: {

            get: async (rifa) => {
                const response = await fetch(`${url}/rifas/getPromocoes`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa
                    })
                });
                return response.json();
            },

            create: async (rifa, promocao) => {
                const response = await fetch(`${url}/rifas/createPromocao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        ...promocao
                    })
                });
                return response.json();
            },

            remove: async (promocao) => {
                const response = await fetch(`${url}/rifas/deletePromocao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        id: promocao
                    })
                });
                return response.json();
            },

            update: async (promocao) => {
                const response = await fetch(`${url}/rifas/updatePromocao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        ...promocao
                    })
                });
                return response.json();
            },

            numeros_em_dobro: async (rifa, on_off) => {
                const response = await fetch(`${url}/promocoes/dobrodenumeros`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        dobrados: on_off
                    })
                });
                return response.json();
            },

            usun: async (rifa, on_off) => {
                const response = await fetch(`${url}/promocoes/setusun`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        status: on_off
                    })
                });
                return response.json();
            }

        },

        ranking: {

            get: async (rifa) => {
                const response = await fetch(`${url}/rifas/ranking`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa
                    })
                });
                return response.json();
            },

            getDia: async (rifa, dia) => {
                const response = await fetch(`${url}/rifas/rankingDia`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        dia
                    })
                });
                return response.json();
            },

            getPremios: async (rifa) => {
                const response = await fetch(`${url}/rifas/getPremiosRanking`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa
                    })
                });
                return response.json();
            },

            setPremio: async (rifa, posicao, premio) => {
                const response = await fetch(`${url}/rifas/createPremioRanking`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        posicao,
                        premio
                    })
                });
                return response.json();
            },

            removePremio: async (premio) => {
                const response = await fetch(`${url}/rifas/deletePremioRanking`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        id: premio
                    })
                });
                return response.json();
            },

            updatePremio: async (premio) => {
                const response = await fetch(`${url}/rifas/updatePremioRanking`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        ...premio
                    })
                });
                return response.json();
            }

        },

        resultados: {

            loteriafederal: async (concurso) => {
                const response = await fetch(`${url}/rifas/loteriafederal`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        concurso
                    })
                });
                return response.json();
            },

            vencedor: async (rifa, regra, concurso) => {
                const response = await fetch(`${url}/rifas/vencedorLoteriaFederal`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        regra,
                        concurso
                    })
                });
                return response.json();
            },

            search1R: async (rifa, numero) => {
                const response = await fetch(`${url}/rifas/search1R`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        numero
                    })
                });
                return response.json();
            }

        },

        botoes: {

            get: async (rifa) => {
                const response = await fetch(`${url}/rifas/getBotoes`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa
                    })
                });
                return response.json();
            },

            create: async (rifa, quantidade, is_destaque) => {
                const response = await fetch(`${url}/rifas/createBotao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        rifa,
                        quantidade,
                        is_destaque
                    })
                });
                return response.json();
            },

            remove: async (botao) => {
                const response = await fetch(`${url}/rifas/deleteBotao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        id: botao
                    })
                });
                return response.json();
            },

            update: async (botao) => {
                const response = await fetch(`${url}/rifas/updateBotao`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Authorization': `Bearer ${resgataToken()}`
                    },
                    body: JSON.stringify({
                        ...botao
                    })
                });
                return response.json();
            }

        }

    },

    relatorios: {

        vendas: async (inicio, fim, empresa) => {
            const response = await fetch(`${url}/relatorios/vendas`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    inicio,
                    fim,
                    empresa
                })
            });
            return response.json();
        },

        empresa: async (empresa) => {
            const response = await fetch(`${url}/relatorios/empresa`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Authorization': `Bearer ${resgataToken()}`
                },
                body: JSON.stringify({
                    empresa
                })
            });
            return response.json();
        }

    }

}

export { url, api, url_storage };