import React, {useState, useEffect} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/login.css'

import Toast from '../components/toast/Toast';

import md5 from 'md5';

import { Button, TextField, Typography } from '@mui/material';

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';

function Login() {

    const { login } = useAuth();

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = (e) => {
        e.preventDefault();

        api.login(email, md5(password)).then(response => {
            
            if (response.error) {
                Toast.error(response.error);
                return;
            }

            login(response.token);
            Toast.success('Login realizado com sucesso');

            window.location.href = '/home';

        }).catch(error => {
            Toast.error('Erro ao realizar login');
        });
    };

    useEffect(() => {

    }, [])

    return(
        <div>

            <section class="tela-1 d-flex justify-content-center text-center align-items-center flex-column">

                <div>

                    <h3>Acesso ao RifaHit Empresas</h3>
                    <div class="lead mb-3">Preencha os campos abaixo para acessar o dashboard</div>

                    <form name='login' onSubmit={e => handleLogin(e)}>

                        <TextField
                            label="Email"
                            fullWidth
                            margin="normal"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type='email'
                        />

                        <TextField
                            label="Senha"
                            type="password"
                            fullWidth
                            margin="normal"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className='mb-4'
                        />

                        <Button className='py-3 px-5' variant="contained" type='submit' color="success">
                            Entrar
                        </Button>

                    </form>
                    
                </div>

            </section>

            <LoadingSplash active={loading}/>

        </div>
    )
}

export default Login