import React, {useState, useRef, useEffect} from 'react';

import './modalNumerosPremiados.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Toast from '../../toast/Toast';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import { api } from '../../../apis/connect';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

function ModalNumerosPremiados(props) {

    const [replay, setReplay] = useState(0);

    const [numeros, setNumeros] = useState([]);

    const [addNumeros, setAddNumeros] = useState([]);
    const [addRandom, setAddRandom] = useState([]);

    const [numero, setNumero] = useState('');
    const [premio, setPremio] = useState('');
    const [premio_random, setPremioRandom] = useState('');

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        props?.rifa?.id && api.rifas.numerosPremiados.get(props?.rifa?.id).then(response => {

            if (response.error) {
                setLoading(false);
                return;
            }

            setNumeros(response);

            setLoading(false);

        });

    }, [props?.rifa?.id, replay]);

    let handlerAddNumero = () => {

        if (!numero || !premio) {
            return;
        }

        if (numeros.find(n => n.numero === numero)) {
            Toast.warning('Número já está na lista de números premiados');
            return;
        }

        if (numero > (props.rifa.sonho ? props.rifa.sonho : props.rifa.quant)) {
            Toast.warning('Número é maior que a quantidade de números disponíveis');
            return;
        }

        if (addNumeros.find(n => n.numero === numero)) {
            Toast.warning('Número já está na lista de números a serem adicionados');
            return;
        }

        setAddNumeros([...addNumeros, {numero, premio}]);

    }

    let handlerSave = async () => {

        setLoading(true);

        let adicionados = false;
        
        if (addNumeros.length > 0) {

            await api.rifas.numerosPremiados.set(props?.rifa?.id, {"numeros": addNumeros}).then(response => {

                if (response.error) {
                    setTimeout(() => {
                        Toast.warning(response.error);
                    }, 1000);
                    return;
                }

                Toast.success('Números premiados adicionados com sucesso');

                setAddNumeros([]);

                adicionados = true;

            });

        }

        if (addRandom.length > 0) {

            await api.rifas.numerosPremiados.set(props?.rifa?.id, {"random": true, "premios": addRandom}).then(response => {

                if (response.error) {
                    setTimeout(() => {
                        Toast.warning(response.error);
                    }, 1000);
                    return;
                }

                Toast.success('Prêmios adicionados com sucesso');

                setAddRandom([]);

                adicionados = true;

            });

        }

        if (adicionados) {
            setReplay(replay+1);
        } else {
            Toast.warning('Nenhum número ou prêmio foi adicionado');
        }

        setLoading(false);

    }

    let handlerRemoveAddList = (index, random) => {

        if (random) {
            setAddRandom(addRandom.filter((premio, idx) => idx !== index));
        } else {
            setAddNumeros(addNumeros.filter((numero, idx) => idx !== index));
        }

    }

    let handlerRemove = (numero) => {
            
            setLoading(true);
    
            api.rifas.numerosPremiados.remove(props?.rifa?.id, [numero]).then(response => {
    
                if (response.error) {
                    Toast.error(response.error);
                    return;
                }
    
                Toast.success('Número removido com sucesso');
    
                setReplay(replay+1);
    
                setLoading(false);
    
            });
    }

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rifa #{props?.rifa?.id} - Números premiados
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h5 class='d-block mb-2'>Números:</h5>
                <div class='d-flex justify-content-center my-5 flex-wrap'>

                    {numeros && numeros.length > 0 ? numeros.map((numero, index) => (
                        <button key={index} className={`btn btn-outline-${numero.clientes_id ? 'danger' : 'success'} m-2 d-inline-flex flex-column justify-content-center position-relative rh-numbers`}>
                            <b class='mx-auto'>{numero.numero}</b>
                            <div class='mx-auto'>{numero.premio}</div>
                            <div class='rh-btn-remove d-none' onClick={() => handlerRemove(numero.numero)}><ClearIcon/></div>
                        </button>
                    )) : 'Nenhum número premiado'}

                </div>

                {addNumeros && addNumeros.length > 0 && <>
                    <h6 class='d-block mb-2'>Números a serem adicionados:</h6>
                    <div class='d-flex justify-content-center my-5 flex-wrap'>

                        {addNumeros && addNumeros.length > 0 ? addNumeros.map((numero, index) => (
                            <button key={index} className='btn btn-warning m-2 d-inline-flex flex-column justify-content-center text-white position-relative'>
                                <b class='mx-auto'>{numero.numero}</b>
                                <div class='mx-auto'>{numero.premio}</div>
                                <div class='rh-btn-remove' onClick={() => handlerRemoveAddList(index)}><ClearIcon/></div>
                            </button>
                        )) : 'Nenhum número a ser adicionado'}

                    </div>
                </>
                }

                {addRandom && addRandom.length > 0 && <>
                    <h6 class='d-block mb-2'>Prêmios a serem adicionados aleatoriamente:</h6>
                    <div class='d-flex justify-content-center my-5 flex-wrap'>

                        {addRandom && addRandom.length > 0 ? addRandom.map((premio_random, index) => (
                            <button key={index} className='btn btn-warning m-2 d-inline-flex flex-column justify-content-center text-white position-relative'>
                                <div class='mx-auto'>{premio_random}</div>
                                <div class='rh-btn-remove' onClick={() => handlerRemoveAddList(index, true)}><ClearIcon/></div>
                            </button>
                        )) : 'Nenhum prêmio a ser adicionado'}

                    </div>
                </>
                }

                <h5 class='d-block my-3'>Adicionar números premiados</h5>

                <div class='row'>

                    <div class='col-6'>
                        <label class='d-block mb-2'>Número:</label>
                        <input 
                            class='form-control mb-2' 
                            placeholder='000000000' 
                            type='number' 
                            min='1' 
                            max={props.rifa.sonho ? props.rifa.sonho : props.rifa.quant} 
                            value={numero} 
                            onChange={(e) => setNumero(e.target.value)}
                        ></input>
                    </div>

                    <div class='col-6 mb-2'>
                        <label class='d-block mb-2'>Prêmio:</label>
                        <input 
                            class='form-control mb-2' 
                            placeholder='R$ 00,00 ou qualquer coisa que desejar'
                            value={premio} 
                            onChange={(e) => setPremio(e.target.value)}
                        ></input>
                    </div>

                    <div class='col-12'>
                        <button class='btn btn-dark d-flex' onClick={handlerAddNumero}><AddIcon className='me-1'/>Adicionar</button>
                    </div>

                </div>

                <h5 class='d-block mb-3 mt-5'>Adicionar prêmios a números aleatórios</h5>

                <div class='row mt-3'>

                    <div class='col-12 mb-2'>
                        <label class='d-block mb-2'>Prêmio a ser adicionado:</label>
                        <input class='form-control mb-2' placeholder='R$ 00,00' value={premio_random} onChange={(e) => setPremioRandom(e.target.value)}></input>
                    </div>
                        
                    <div class='col-12'>
                        <button class='btn btn-dark d-flex' onClick={() => setAddRandom([...addRandom, premio_random])}><AddIcon className='me-1'/>Adicionar</button>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
            {((addNumeros && addNumeros.length > 0) || (addRandom && addRandom.length > 0)) && <Button className='rh-btn-orange d-flex' onClick={handlerSave}><SaveIcon className='me-2'/>Salvar novos números premiados</Button>}
            <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            <LoadingSplash active={loading} absolute/>

        </Modal>
    );
}


export default ModalNumerosPremiados;
