import React, {useEffect, useState, useRef} from 'react';

import './modalRifaCriar.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import LoadingSplash from '../../LoadingSplash/LoadingSplash';

import { api } from '../../../apis/connect';

import {mask_input_money} from '../../../utils/masks';
import InputMask from 'react-input-mask';

import Toast from '../../toast/Toast';
import Swal from 'sweetalert2';

import { useAuth } from '../../../contexts/AuthContext';


function ModalRifaCriar(props) {

    const {usuarioLogado} = useAuth();

    const [rifa, setRifa] = useState({
        'nome': '',
        'describe': '',
        'valor': 0.01,
        'expire_at': '',
        'min': 1,
        'max': null,
        'elastica': 1,
        'ranking': 1,
        'quant': 1000000,
    });

    const [loading, setLoading] = useState(false);

    useEffect(() => {

    }, [props]);

    function handlerOnChange(e){
        setRifa({...rifa, [e.target.name]: e.target.value});
    }

    function handlerSave() {
            
        setLoading(true);

        api.rifas.create(rifa).then(response => {

            if (response.error) {
                setLoading(false);
                Toast.error(response.error);
                return;
            }

            Toast.success('Rifa criada com sucesso!');
            props.onHide();
            
            window.location.href = '/rifa/' + response.id;

            setLoading(false);

        });
    }

    function confirmSave() {
        Swal.fire({
            title: 'Atenção! Esta é uma ação importante.',
            text: 'Deseja realmente criar essa rifa?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Criar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: "#198754",
            cancelButtonColor: "#000"
        }).then((result) => {
            if (result.isConfirmed) {
                handlerSave();
            }
        });
    }

    return (

        <>

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {usuarioLogado?.nome} - Criar nova rifa
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <form className="row">

                            <div className="col-12 mb-3">
                                <div>
                                    <b class='d-block mb-2'>Nome da rifa:</b> 
                                    <input class='form-control' name='nome' value={rifa.nome} onChange={handlerOnChange} required placeholder='Exemplo: BMW da sorte!'></input>
                                </div>
                            </div>

                            <div className="col-12 mb-4">
                                <div>
                                    <b class='d-block mb-2'>Descrição:</b> 
                                    <textarea class='form-control' name='describe' 
                                        value={rifa.describe} style={{minHeight: '200px'}} 
                                        onChange={handlerOnChange} 
                                        required 
                                        placeholder='Descreva a rifa, o que ela é, o que ela faz, como ela funciona, regras do sorteio, etc...'
                                    ></textarea>
                                </div>
                            </div>

                            <div class='row mb-5'>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Quantidade de números:</b> 
                                        <input className='form-control' mask='999.999.999' type='number' name='quant' value={rifa.quant} onChange={handlerOnChange} required></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Valor:</b>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">R$</span>
                                            <input class='form-control' name='valor' type='text' value={rifa.valor.toLocaleString()} onChange={(event) => {
                                                mask_input_money(event);
                                                handlerOnChange(event);
                                            }} required></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Lucro previsto:</b>
                                        <input class='form-control' disabled type='text' value={
                                            (((typeof rifa.valor) === 'number' ? rifa.valor : parseFloat(String(rifa.valor).replace('R$ ', '').replace('.', '').replace(',', '.'))) * rifa.quant).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})
                                        }></input>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-5 justify-content-center'>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Mínimo de números para uma compra:</b>
                                        <input class='form-control text-center' name='min' type='number' value={rifa.min} onChange={handlerOnChange}></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Máximo de números para uma compra:</b>
                                        <input class='form-control text-center' name='max' type='number' value={rifa.max} onChange={handlerOnChange} placeholder='Ilimitado'></input>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-2 justify-content-center'>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Mostrar ranking:</b>
                                        <select class='form-select' name='ranking' value={rifa.ranking} onChange={handlerOnChange}>
                                            <option value='1'>Sim</option>
                                            <option value='0'>Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Data do sorteio:</b>
                                        <input class='form-control' name='expire_at' type='datetime-local' value={rifa.expire_at} onChange={handlerOnChange}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Elástica:</b>
                                        <select class='form-select' name='elastica' value={rifa.elastica} onChange={handlerOnChange}>
                                            <option value='1'>Sim</option>
                                            <option value='0'>Não</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-success' onClick={confirmSave} disabled={loading}>Criar</Button>
                    <Button className='btn-dark' onClick={props.onHide}>Cancelar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute/>

            </Modal>

        </>
    );
}


export default ModalRifaCriar;
