import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './menu.css';

import SemLogo from '../../assets/imgs/menu/semlogo.png';
import LogoRifahit from '../../assets/imgs/logos/3.png';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

import { useAuth } from '../../contexts/AuthContext';
import { api, url_storage } from '../../apis/connect';

function MenuLogin(){
    const [imagem, setImagem] = useState(LogoRifahit);
    const [menu, setMenu] = useState(false);

    const {logout, usuarioLogado } = useAuth();

    useEffect(() => {

        let sidebar = document.querySelector('.rh-sidebar')

        if (menu) {
            sidebar.classList.toggle('d-flex', true);
        } else {
            sidebar.classList.toggle('d-flex', false);
        }

    }, [usuarioLogado, menu]);

    return(
        <header>
            <Link to='/' className="logo"><img src={imagem} alt="Logo" class="sem-logo"/></Link>
            <ul class="navigation w-100 ps-sm-5 pe-sm-5">
                {/* <li><input type='text' placeholder='Search'></input></li> */}
                {/* <li class='ms-auto me-sm-3'><Link className='btn btn-outline-success' to='/remarketing'>Remarketing</Link></li> */}
                <li class='ms-auto btn btn-success px-5' onClick={logout}>Sair</li>
            </ul>
            {!menu ? <MenuIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></MenuIcon>
            :
            <CloseIcon className="toggle-rh" onClick={() => setMenu(!menu)} alt=''></CloseIcon>}

        </header>
    )
}

export default MenuLogin