import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Toast from '../../toast/Toast';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import { api } from '../../../apis/connect';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

import { mask_money } from '../../../utils/masks';
import CurrencyInput from 'react-currency-input-field';

function ModalRifaPromocoes(props) {

    const [replay, setReplay] = useState(0);

    const [quantidade, setQuantidade] = useState(1);
    const [promos, setPromos] = useState([]);

    const [addPromo, setAddPromo] = useState([]);

    const [money, setMoney] = useState(0);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        props?.rifa?.id && api.rifas.promocoes.get(props?.rifa?.id).then(response => {

            if (response.error) {
                setLoading(false);
                setPromos([]);
                return;
            }

            setPromos(response);

            setLoading(false);

        });

    }, [props?.rifa?.id, replay]);

    let handlerAddPromo = () => {

        if (!money) {
            Toast.warning('Valor não pode ser vazio');
            return;
        }

        if (!quantidade) {
            Toast.warning('Quantidade não pode ser vazia');
            return;
        }

        if (promos.find(n => n.quantidade == quantidade)) {
            Toast.warning('Quantidade já está na lista de promoções');
            return;
        }

        if (money < props.rifa.valor) {
            Toast.warning('Valor não pode ser menor que R$ 0,01');
            return;
        }

        if (quantidade < 1) {
            Toast.warning('Quantidade não pode ser menor que 1');
            return;
        }

        if (addPromo.find(n => n.quantidade == quantidade)) {
            Toast.warning('Quantidade já está na lista de promoções a serem adicionadas');
            return;
        }

        if (quantidade > (props.rifa.sonho ? props.rifa.sonho : props.rifa.quant)) {
            Toast.warning('Quantidade é maior que a quantidade de números disponíveis');
            return;
        }

        setAddPromo([...addPromo, {valor: money, quantidade}]);

    }

    let handlerSave = async () => {

        
        if (addPromo.length > 0) {
            
            setLoading(true);

            addPromo.forEach(promo => {
            
                api.rifas.promocoes.create(props?.rifa?.id, promo).then(response => {
                
                    if (response.error) {
                        Toast.warning(response.error);
                        setLoading(false);
                        return;
                    }
    
                    Toast.success(`Promoção de ${mask_money(promo.valor)} adicionada com sucesso`);

                    handlerRemoveAddList(addPromo.indexOf(promo));
    
                });

            });

            setTimeout(() => {
                setReplay(replay+1);
                setLoading(false);
            }, 4000);


        }


    }

    let handlerRemoveAddList = (index) => {

        addPromo.splice(index, 1);
        setAddPromo([...addPromo]);

    }

    let handlerRemove = (promo) => {
            
            setLoading(true);
    
            api.rifas.promocoes.remove(promo).then(response => {
    
                if (response.error) {
                    Toast.error(response.error);
                    return;
                }
    
                Toast.success('Promoção removida com sucesso');
    
                setReplay(replay+1);
    
                setLoading(false);
    
            });
    }

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rifa #{props?.rifa?.id} - Promoções
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h5 class='d-block mb-2'>Promoções ativas:</h5>
                <div class='d-flex justify-content-center my-5 flex-wrap'>

                    {promos && promos.length > 0 ? promos.map((promo, index) => (
                        <button key={index} className={`btn btn-outline-success m-2 d-inline-flex flex-column justify-content-center position-relative rh-numbers`}>
                            <b class='mx-auto'>{mask_money(promo.valor)}</b>
                            <div class='mx-auto'>{promo.quantidade}</div>
                            <div class='rh-btn-remove d-none' onClick={() => handlerRemove(promo.id)}><ClearIcon/></div>
                        </button>
                    )) : 'Nenhuma promoção cadastrada'}

                </div>

                {addPromo && addPromo.length > 0 && <>
                    <h6 class='d-block mb-2'>Promoções a serem adicionadas:</h6>
                    <div class='d-flex justify-content-center my-5 flex-wrap'>

                        {addPromo && addPromo.length > 0 ? addPromo.map((promo, index) => (
                            <button key={index} className='btn btn-outline-warning m-2 d-inline-flex flex-column justify-content-center position-relative'>
                                <b class='mx-auto'>{mask_money(promo.valor)}</b>
                                <div class='mx-auto'>{promo.quantidade}</div>
                                <div class='rh-btn-remove' onClick={() => handlerRemoveAddList(index)}><ClearIcon/></div>
                            </button>
                        )) : 'Nenhuma promoção a ser adicionada'}

                    </div>
                </>
                }

                <h5 class='d-block my-3'>Adicionar valor promocional:</h5>

                <div class='row'>

                    <div class='col-6'>
                        <label class='d-block mb-2'>Valor:</label>
                        <CurrencyInput
                            className='form-control mb-2'
                            placeholder='R$ 0,00'
                            onValueChange={(value, name, values) => setMoney(values.float)}
                            intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                            decimalScale={2}
                            fixedDecimalLength={true}
                        />
                    </div>

                    <div class='col-6 mb-2'>
                        <label class='d-block mb-2'>Quantidade de números:</label>
                        <input 
                            class='form-control mb-2' 
                            placeholder='000000' 
                            value={quantidade} 
                            onChange={(e) => setQuantidade(e.target.value)} 
                            type='number' 
                            min='1' 
                            max={Math.round((props.rifa.sonho ? props.rifa.sonho : props.rifa.quant) * 0.05)} >
                        </input>
                    </div>

                    <div class='col-12'>
                        <button class='btn btn-dark d-flex' onClick={handlerAddPromo}><AddIcon className='me-1'/>Adicionar</button>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                {addPromo && addPromo.length > 0 && <Button className='rh-btn-orange d-flex' onClick={handlerSave}><SaveIcon className='me-2'/>Salvar novas promoções</Button>}
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            <LoadingSplash active={loading} absolute/>

        </Modal>
    );
}


export default ModalRifaPromocoes;
