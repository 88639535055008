import React, {useEffect, useState, useRef} from 'react';

import './modalRifaEditar.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import LoadingSplash from '../../LoadingSplash/LoadingSplash';

import { api } from '../../../apis/connect';

import CurrencyInput from 'react-currency-input-field';
import {mask_input_money} from '../../../utils/masks';

import Toast from '../../toast/Toast';
import Swal from 'sweetalert2';


function ModalRifaEditar(props) {

    const [rifa, setRifa] = useState({});
    const [modify, setModify] = useState(false);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        props?.rifa && api.rifas.get(props?.rifa).then(response => {

            if (response.error) {
                return;
            }

            setRifa(response);

            setLoading(false);

        });

    }, [props]);

    function handlerOnChange(e){
        setRifa({...rifa, [e.target.name]: e.target.value});
        setModify(true);
    }

    function handlerSave() {
            
        setLoading(true);

        api.rifas.update(rifa).then(response => {

            if (response.error) {
                setLoading(false);
                Toast.error(response.error);
                return;
            }

            Toast.success('Rifa editada com sucesso!');

            setLoading(false);

        });
    }

    function confirmSave() {
        Swal.fire({
            title: 'Atenção! Esta é uma ação importante.',
            text: 'Deseja realmente salvar as alterações feitas nesta rifa?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Salvar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: "#198754",
            cancelButtonColor: "#000"
        }).then((result) => {
            if (result.isConfirmed) {
                handlerSave();
            }
        });
    }

    return (

        <>

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Rifa #{props?.rifa} - Editar
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <form className="row">

                            <div className="col-12 mb-3">
                                <div>
                                    <b class='d-block mb-2'>Nome:</b> 
                                    <input class='form-control' name='nome' value={rifa.nome} onChange={handlerOnChange}></input>
                                </div>
                            </div>

                            <div className="col-12 mb-4">
                                <div>
                                    <b class='d-block mb-2'>Descrição:</b> 
                                    <textarea class='form-control' name='describe' value={rifa.describe} style={{minHeight: '200px'}} onChange={handlerOnChange}></textarea>
                                </div>
                            </div>

                            <div class='row mb-5'>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Quantidade:</b> 
                                        <input class='form-control' disabled value={(rifa?.sonho ? rifa.sonho : rifa?.quant)?.toLocaleString('pt-br')+' de números'}></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Valor:</b>
                                        <div class="input-group mb-3">
                                            <span class="input-group-text">R$</span>
                                            <input class='form-control' name='valor' type='text' value={rifa?.valor?.toLocaleString()} onChange={(event) => {
                                                mask_input_money(event);
                                                handlerOnChange(event);
                                            }}></input>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Data do sorteio:</b>
                                        <input class='form-control' name='expire_at' type='datetime-local' value={rifa.expire_at} onChange={handlerOnChange}></input>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-5'>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Mínimo:</b>
                                        <input class='form-control text-center' name='min' type='number' value={rifa.min} onChange={handlerOnChange}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Máximo:</b>
                                        <input class='form-control text-center' name='max' type='number' value={rifa.max} onChange={handlerOnChange}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Elástica:</b>
                                        <select class='form-select' name='elastica' value={rifa.elastica} onChange={handlerOnChange}>
                                            <option value='1'>Sim</option>
                                            <option value='0'>Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Fidelidade:</b>
                                        <select class='form-select' name='fidelidade' value={rifa.fidelidade} onChange={handlerOnChange}>
                                            <option value='1'>Sim</option>
                                            <option value='0'>Não</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-2 justify-content-center'>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Mostrar ranking:</b>
                                        <select class='form-select' name='ranking' value={rifa.ranking} onChange={handlerOnChange}>
                                            <option value='1'>Sim</option>
                                            <option value='0'>Não</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Ativa:</b>
                                        <select class='form-select' name='active' value={rifa.active} onChange={handlerOnChange}>
                                            <option value='1'>Sim</option>
                                            <option value='0'>Não</option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {modify && <Button className='btn-success' onClick={confirmSave} disabled={loading}>Salvar</Button>}
                    <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute/>

            </Modal>

        </>
    );
}


export default ModalRifaEditar;
