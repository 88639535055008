import React, {useEffect, useState, useRef} from 'react';
import { useHistory, useParams } from 'react-router-dom';

import '../../assets/css/rifa.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MoneyIcon from '@mui/icons-material/Money';
import StarsIcon from '@mui/icons-material/Stars';
import { ArrowBackIos, AttachMoney, CardGiftcard, Dialpad, Edit, Filter1, Link } from '@mui/icons-material';

import LoadingSplash from '../../components/LoadingSplash/LoadingSplash';

import { api } from '../../apis/connect'

import TableExtrato from '../../components/tableExtrato/TableExtrato';

import ModalNumerosPremiados from '../../components/modals/modalNumerosPremiados/ModalNumerosPremiados';
import ModalRifaPromocoes from '../../components/modals/modalRifaPromocoes/ModalRifaPromocoes';
import ModalRifaImagens from '../../components/modals/modalRifaImagens/ModalRifaImagens';
import ModalRifaRanking from '../../components/modals/modalRifaRanking/ModalRifaRanking';
import ModalDefault from '../../components/modals/modalDefault/ModalDefault';
import ModalRifaBotoes from '../../components/modals/modalRifaBotoes/ModalRifaBotoes';
import Toast from '../../components/toast/Toast';
import Swal from 'sweetalert2';
import { mask_money } from '../../utils/masks';

function Rifa() {

    const {id} = useParams();

    // const [replay, setReplay] = useState(0);

    const [rifa, setRifa] = useState({});

    const [loading, setLoading] = useState(true);

    const [slug, setSlug] = useState('');

    const [showNumerosPremiados, setShowNumerosPremiados] = useState(false);
    const [showPromocoes, setShowPromocoes] = useState(false);
    // const [showGanhador, setShowGanhador] = useState(false);
    const [showImagens, setShowImagens] = useState(false);
    const [showRanking, setShowRanking] = useState(false);
    const [showExtrato, setShowExtrato] = useState(false);
    const [showBotoes, setShowBotoes] = useState(false);

    const [promoDobro, setPromoDobro] = useState(false);
    const [promoUsun, setPromoUsun] = useState(false);

    const [replay, setReplay] = useState(0);

    useEffect(() => {

        setLoading(true);

        id && api.rifas.getPlus(id).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setRifa(response);
            setSlug(response.slug);
            setPromoDobro(response.promo_dobro ? true : false);

            setLoading(false);

        });

    }, [id, replay]);

    function handlerSlug() {

        // tem certeza que deseja alterar o link da rifa?
        Swal.fire({
            title: 'Tem certeza que deseja alterar o link da rifa?',
            text: 'Ao alterar o link da rifa, talvez tenha que alterar o link em suas redes sociais, sites e anúncios.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, alterar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: "#198754",
            cancelButtonColor: "#000"
        }).then((result) => {
            if (result.isConfirmed) {
                
                setLoading(true);

                api.rifas.slug(rifa.id, slug).then(response => {

                    if (response.error) {
                        Toast.error(response.error);
                        setLoading(false);
                        return;
                    }

                    setReplay(replay+1);

                    setLoading(false);

                });

            }
        });

    }

    function handlerPromoDobro(on_off) {
            
        setLoading(true);

        api.rifas.promocoes.numeros_em_dobro(rifa.id, on_off).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            setPromoDobro(on_off);
            Toast.success('Promoção de números em dobro '+ (on_off ? 'ativada' : 'desativada') +' com sucesso!');
            setLoading(false);

        });

    }

    function handlerPromoUsun(on_off) {
            
        setLoading(true);

        api.rifas.promocoes.usun(rifa.id, on_off).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            setPromoUsun(on_off);
            Toast.success('Promoção "Um seguidor, um número" '+ (on_off ? 'ativada' : 'desativada') +' com sucesso!');
            setLoading(false);

        });

    }

    return (

        <div className='container mt-4'>

            {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

            <div class='mb-4'>
                <h3>Rifa #{id}</h3>
                <p>Veja os detalhes e relatórios da rifa</p>
            </div>

            <div className="row">

                <div className="col-12 mb-3">
                    <div>
                        <b class='d-block mb-2'>Nome:</b> 
                        <input class='form-control' readOnly value={rifa.nome}></input>
                    </div>
                </div>

                <div className="col-12 mb-4">
                    <div>
                        <b class='d-block mb-2'>Descrição:</b> 
                        <textarea readOnly class='form-control' value={rifa.describe} style={{minHeight: '200px'}}></textarea>
                    </div>
                </div>
                
                <div className="col-12 mb-4">

                    <div class='d-flex justify-content-start align-items-center flex-wrap'>

                        <button class='btn btn-dark d-flex me-4 mb-3' onClick={() => {

                            navigator.clipboard.writeText(rifa.empresa.domain+'/rifa/'+rifa.slug);
                            Toast.success('Link copiado para a área de transferência!');

                        }}><Link className='me-2'/>Copiar link da rifa</button>
                        
                        <input class='ms-2 form-control w-50 mb-3' value={slug} onChange={(e) => setSlug(e.target.value)}></input>

                        <button class='btn btn-dark d-flex ms-3 mb-3' title='Editar link' onClick={handlerSlug}><Edit/></button>

                    </div>

                </div>

                <div class='row mb-3'>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Quantidade:</b> 
                            <input class='form-control' readOnly value={(rifa?.sonho ? rifa.sonho : rifa?.quant)?.toLocaleString('pt-br')+' números'}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Vendidos:</b>
                            <input class='form-control' readOnly value={rifa?.vendidos?.toLocaleString('pt-br')+' números'}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Porcentagem de vendas:</b>
                            <input class='form-control' readOnly value={rifa?.porcentagem_real?.toLocaleString('pt-br')+'%'}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Lucro total:</b>
                            <input class='form-control' readOnly disabled value={mask_money(rifa?.relatorio?.total)}></input>
                        </div>
                    </div>

                </div>

                <div class='row mb-3'>

                    <div className="col-6 col-md-4 mb-2">
                        <div>
                            <b class='d-block mb-2'>Valor:</b>
                            <input class='form-control' readOnly value={mask_money(rifa?.valor)}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-4 mb-2">
                        <div>
                            <b class='d-block mb-2'>Criada em:</b>
                            <input class='form-control' readOnly value={new Date(rifa.created_at).toLocaleDateString()}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-4 mb-2">
                        <div>
                            <b class='d-block mb-2'>Data do sorteio:</b>
                            <input class='form-control' readOnly value={!isNaN((new Date(rifa.expire_at)).getDate()) ? (new Date(rifa.expire_at)).toLocaleDateString() : 'Não informada'}></input>
                        </div>
                    </div>

                </div>

                <div class='row mb-4'>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Mínimo:</b>
                            <input class='form-control' readOnly value={rifa.min?.toLocaleString('pt-br')}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Máximo:</b>
                            <input class='form-control' readOnly value={rifa.max ? rifa.max?.toLocaleString('pt-br') : 'Ilimitado'}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Elástica:</b>
                            <input class='form-control' readOnly value={rifa.elastica ? 'Sim' : 'Não'}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Fidelidade:</b>
                            <input class='form-control' readOnly value={rifa.fidelidade ? 'Sim' : 'Não'}></input>
                        </div>
                    </div>

                </div>

                <div class='row mb-2 justify-content-center'>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Promoção "Um seguidor, um número":</b>
                            <div class='d-flex'>
                                <label class='me-3'>Ativada:</label>
                                <div class='form-check form-switch'>
                                    <input type="checkbox" role="switch" class='form-check-input' checked={promoUsun} onChange={(e) => handlerPromoUsun(e.target.checked)}></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Promoção números em dobro:</b>
                            <div class='d-flex'>
                                <label class='me-3'>Ativada:</label>
                                <div class='form-check form-switch'>
                                    <input type="checkbox" role="switch" class='form-check-input' checked={promoDobro} onChange={(e) => handlerPromoDobro(e.target.checked)}></input>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Ativa:</b>
                            <input class='form-control' readOnly value={rifa.active ? 'Sim' : 'Não'}></input>
                        </div>
                    </div>

                    <div className="col-6 col-md-3 mb-2">
                        <div>
                            <b class='d-block mb-2'>Finalizada:</b>
                            <input class='form-control' readOnly value={rifa.finalizada ? 'Sim' : 'Não'}></input>
                        </div>
                    </div>

                </div>

            </div>

            <b class='my-3 d-block'>Ações: </b>
            <div class='d-flex flex-wrap justify-content-start mb-5'>
                {/* <Button className='btn-success d-flex'><EmojiEventsIcon className='me-2'/>Configurar ganhador</Button> */}
                <Button className='btn-dark d-flex m-2' onClick={() => setShowBotoes(true)}><Dialpad className='me-2'/>Botões de compra</Button>
                <Button className='btn-dark d-flex m-2' onClick={() => setShowImagens(true)}><InsertPhotoOutlinedIcon className='me-2'/>Imagens</Button>
                <Button className='d-flex btn-dark m-2' onClick={() => setShowNumerosPremiados(true)}><Filter1 className='me-2'/>Números Premiados</Button>
                <Button className='btn-dark d-flex m-2' onClick={() => setShowRanking(true)}><StarsIcon className='me-2'/>Ranking</Button>
                <Button className='btn-dark d-flex m-2' onClick={() => setShowPromocoes(true)}><CardGiftcard className='me-2'/>Promoções</Button>
                <Button className='btn-dark d-flex m-2' onClick={() => setShowExtrato(true)}><AttachMoney className='me-2'/>Extrato</Button>
            </div>

            <LoadingSplash active={loading}/>

            <ModalNumerosPremiados show={showNumerosPremiados} rifa={rifa} onHide={() => {setShowNumerosPremiados(false)}}/>
            <ModalRifaPromocoes show={showPromocoes} rifa={rifa} onHide={() => {setShowPromocoes(false)}}/>
            <ModalRifaImagens show={showImagens} rifa={rifa} onHide={() => {setShowImagens(false)}}/>
            <ModalRifaRanking show={showRanking} rifa={rifa} onHide={() => {setShowRanking(false)}}/>
            <ModalDefault show={showExtrato} onHide={() => {setShowExtrato(false)}} title={`Rifa #${rifa.id} - Extrato`}>
                <TableExtrato rifa={rifa.id}/>
            </ModalDefault>
            <ModalRifaBotoes show={showBotoes} rifa={rifa} onHide={() => {setShowBotoes(false)}}/>

        </div>
    );
}


export default Rifa;
