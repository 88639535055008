import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';

import { api } from '../../../apis/connect';
import Toast from '../../toast/Toast';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

import InputMask from 'react-input-mask';

function ModalCadastroAfiliado(props) {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);
    
    const [user, setUser] = useState({
        empresa: usuarioLogado?.id,
        nome: '',
        email: '',
        telefone: '',
        cpf: '',
        password: '',
        password_confirmation: ''
    });

    useEffect(() => {

        usuarioLogado?.id && setUser({...user, empresa: usuarioLogado?.id});

    }, [usuarioLogado]);

    function handlerChange(e) {
        setUser({...user, [e.target.name]: e.target.value});
    }

    function handlerSave(e){

        e.preventDefault();

        if (user.password !== user.password_confirmation) {
            Toast.error('As senhas não conferem');
            return;
        }

        if (user.password.length < 8) {
            Toast.error('A senha deve ter no mínimo 8 caracteres');
            return;
        }

        delete user.password_confirmation;

        setLoading(true);

        api.afiliados.create(user).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            Toast.success('Afiliado cadastrado com sucesso');
            setLoading(false);
            props.onHide();

        });

    }

    return (

        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Cadastro de afiliado
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>

                        <form id="cadastro" name='cadastro' class='row mb-3' onSubmit={handlerSave}>

                            <div className='col-6 mb-3'>
                                <div class='ms-1 mb-1'>Nome completo:</div>
                                <input type='text' className='form-control' value={user.nome} name='nome' onChange={handlerChange} placeholder='Nome Completo' required></input>
                            </div>

                            <div className='col-6 mb-3'>
                                <div class='ms-1 mb-1'>Telefone:</div>
                                <Form.Control type='text' className='form-control' value={user.telefone} name='telefone' onChange={handlerChange} as={InputMask} mask='(99) 99999-9999' placeholder='(00) 00000-0000' required></Form.Control>
                            </div>

                            <div className='col-6 mb-3'>
                                <div class='ms-1 mb-1'>Email:</div>
                                <input type='email' className='form-control' value={user.email} name='email' onChange={handlerChange} placeholder='email@email.com' required></input>
                            </div>

                            <div className='col-6 mb-3'>
                                <div class='ms-1 mb-1'>CPF:</div>
                                <Form.Control type='text' className='form-control' value={user.cpf} name='cpf' onChange={handlerChange} as={InputMask} mask='999.999.999-99' placeholder='000.000.000-00' required></Form.Control>
                            </div>

                            <div className='col-6 mb-3'>
                                <div class='ms-1 mb-1'>Senha:</div>
                                <input type='password' className='form-control' value={user.password} name='password' onChange={handlerChange} placeholder='Senha' required></input>
                            </div>

                            <div className='col-6 mb-3'>
                                <div class='ms-1 mb-1'>Confirmação de senha:</div>
                                <input type='password' className='form-control' value={user.password_confirmation} name='password_confirmation' onChange={handlerChange} placeholder='Confirmação de senha' required></input>
                            </div>

                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-success' type='submit' disabled={loading} form='cadastro'>Cadastrar</Button>
                    <Button className='btn-dark' onClick={props.onHide}>Cancelar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute={true} text='Cadastrando afiliado...' />

            </Modal>

        </>

    );
}


export default ModalCadastroAfiliado;
