import React, {useState, useEffect} from 'react';

import './modalpagamentosincompletos.css';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import MyTable from '../../mytable/MyTable';

function ModalCadastro(props) {

    const [data, setData] = useState([]);

    useEffect(() => {
        
        setData(props.pagamentos);
    
    }, [props.pagamentos])

    const columns = [
        {Header: 'Quantidade', accessor: 'quant'},
        {Header: 'Total', accessor: (row) => row.total.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})},
        {Header: 'Pago', accessor: (row) => row.paid ? 'Sim' : 'Não'},
        {Header: 'Data de criação', accessor: (row) => (new Date(row.created_at)).toLocaleString()},
        {Header: 'Data de pagamento', accessor: (row) => row.paid_at ? (new Date(row.paid_at)).toLocaleString() : 'Não pago'},
    ]

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pagamentos
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>


                <MyTable data={data} columns={columns} />

                <div class='d-flex justify-content-end'>
                    <Button variant='success' className='my-2' type='submit' onClick={() => props.onHide()}>Fechar</Button>
                </div>

            </Modal.Body>

        </Modal>
    );
}


export default ModalCadastro;
