import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Toast from '../../toast/Toast';

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';

import { api } from '../../../apis/connect';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

import { mask_money } from '../../../utils/masks';
import formatDate from '../../../utils/formatDate';

function ModalRifaRanking(props) {

    const [replay, setReplay] = useState(0);

    const [posicao, setPosicao] = useState('');
    const [premios, setPremios] = useState([]);
    const [ranking, setRanking] = useState([]);
    const [rankingDia, setRankingDia] = useState([]);

    const [addPremio, setAddPremio] = useState([]);

    const [inputPremio, setInputPremio] = useState('');

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        props?.rifa?.id && api.rifas.ranking.getPremios(props?.rifa?.id).then(response => {

            if (response.error) {
                setLoading(false);
                setPremios([]);
                return;
            }

            setPremios(response);

            setLoading(false);

        });

        props?.rifa?.id && api.rifas.ranking.get(props?.rifa?.id).then(response => {

            if (response.error) {
                setRanking([]);
                setLoading(false);
                return;
            }

            setRanking(response);

            setLoading(false);

        });

        props?.rifa?.id && handlerRankingDia(formatDate('yyyy-mm-dd'));

    }, [props?.rifa?.id, replay]);

    let handlerAddPremio = () => {

        if (!inputPremio) {
            Toast.warning('Informe o prêmio');
            return;
        }

        if (!posicao) {
            Toast.warning('Informe a posição');
            return;
        }

        if (premios.find(n => n.posicao == posicao)) {
            Toast.warning('Posição já está na lista de premiações');
            return;
        }

        if (posicao < 1) {
            Toast.warning('Posição não pode ser menor que 1');
            return;
        }

        if (addPremio.find(n => n.posicao === posicao)) {
            Toast.warning('Posição já está na lista de premiações a serem adicionadas');
            return;
        }

        setAddPremio([...addPremio, {premio: inputPremio, posicao: posicao}]);

    }

    let handlerSave = async () => {

        
        if (addPremio.length > 0) {
            
            setLoading(true);

            await addPremio.forEach(async (premio) => {
            
                let response = await api.rifas.ranking.setPremio(props?.rifa?.id, premio.posicao, premio.premio)

                if (response.error) {
                    Toast.warning(response.error);
                    setLoading(false);
                    return;
                }

                Toast.success(`Premiação de ${premio.posicao}º lugar adicionado com sucesso`);

                handlerRemoveAddList(addPremio.indexOf(premio));

            });

            setTimeout(() => {
                setReplay(replay+1);
                setLoading(false);
            }, 4000);


        }


    }

    let handlerRemoveAddList = (index) => {

        addPremio.splice(index, 1);
        setAddPremio([...addPremio]);

    }

    let handlerRemove = (premio) => {
            
            setLoading(true);
    
            api.rifas.ranking.removePremio(premio).then(response => {
    
                if (response.error) {
                    Toast.error(response.error);
                    return;
                }
    
                Toast.success('Prêmio removido com sucesso');
    
                setReplay(replay+1);
    
                setLoading(false);
    
            });
    }

    let handlerRankingDia = (dia) => {
            
        setLoading(true);

        api.rifas.ranking.getDia(props?.rifa?.id, dia).then(response => {

            if (response.error) {
                setRankingDia([]);
                setLoading(false);
                return;
            }

            setRankingDia(response);

            setLoading(false);

        });
    }

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rifa #{props?.rifa?.id} - Prêmios do Ranking
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h5 class='d-block mb-4'>Ranking total:</h5>

                <div class='d-flex justify-content-center my-5 flex-wrap'>
                        
                        {ranking && ranking.length > 0 ? ranking.map((rank, index) => (
                            <div key={index} className='m-4 d-inline-flex flex-column justify-content-center position-relative'>
                                <div class='mx-auto fs-4'>
                                    {
                                        index === 0 ? '🥇' :
                                        index === 1 ? '🥈' :
                                        index === 2 ? '🥉' :
                                        `🏅 ${index+1}º`
                                    }
                                </div>
                                <b class='mx-auto'>{rank.nome}</b>
                                <div class='mx-auto'>{rank.total} números</div>
                            </div>
                        )) : 'Nenhum participante no ranking'}

                </div>

                <h5 class='d-block mb-4'>Ranking do dia {formatDate('dd/mm/yyyy')} :</h5>

                <div class='d-flex justify-content-center my-5 flex-wrap'>
                        
                        {rankingDia && rankingDia.length > 0 ? rankingDia.map((rank, index) => (
                            <div key={index} className='m-4 d-inline-flex flex-column justify-content-center position-relative'>
                                <div class='mx-auto fs-4'>
                                    {
                                        index === 0 ? '🥇' :
                                        index === 1 ? '🥈' :
                                        index === 2 ? '🥉' :
                                        `🏅 ${index+1}º`
                                    }
                                </div>
                                <b class='mx-auto'>{rank.nome}</b>
                                <div class='mx-auto'>{rank.total} números</div>
                            </div>
                        )) : 'Nenhum participante no ranking'}

                </div>

                <h5 class='d-block mb-2'>Premiações de ranking:</h5>
                <div class='d-flex justify-content-center my-5 flex-wrap'>

                    {premios && premios.length > 0 ? premios.map((premio, index) => (
                        <button key={index} className={`btn btn-outline-success m-2 d-inline-flex flex-column justify-content-center position-relative rh-numbers`}>
                            <b class='mx-auto'>{premio.posicao}º</b>
                            <div class='mx-auto'>{premio.premio}</div>
                            <div class='rh-btn-remove d-none' onClick={() => handlerRemove(premio.id)}><ClearIcon/></div>
                        </button>
                    )) : 'Nenhum prêmio cadastrado'}

                </div>

                {addPremio && addPremio.length > 0 && <>
                    <h6 class='d-block mb-2'>Prêmios a serem adicionados:</h6>
                    <div class='d-flex justify-content-center my-5 flex-wrap'>

                        {addPremio && addPremio.length > 0 ? addPremio.map((premio, index) => (
                            <button key={index} className='btn btn-outline-warning m-2 d-inline-flex flex-column justify-content-center position-relative'>
                                <b class='mx-auto'>{premio.posicao}º</b>
                                <div class='mx-auto'>{premio.premio}</div>
                                <div class='rh-btn-remove' onClick={() => handlerRemoveAddList(index)}><ClearIcon/></div>
                            </button>
                        )) : 'Nenhum prêmio na lista de premiações a serem adicionadas'}

                    </div>
                </>
                }

                <h5 class='d-block mt-3 mb-4'>Adicionar premiação de ranking:</h5>

                <div class='row'>

                    <div class='col-2 mb-2'>
                        <label class='d-block mb-2'>Colocação:</label>
                        <input 
                            class='form-control mb-2' 
                            placeholder='1º' 
                            value={posicao} 
                            onChange={(e) => setPosicao(e.target.value)} 
                            type='number' 
                            min='1'>
                        </input>
                    </div>

                    <div class='col-6 mb-2'>
                        <label class='d-block mb-2'>Prêmio:</label>
                        <input 
                            type='text' 
                            class='form-control mb-2' 
                            value={inputPremio} 
                            onChange={(e) => setInputPremio(e.target.value)}
                            placeholder='R$ 0,00 ou qualquer coisa que desejar'
                        ></input>
                    </div>

                    <div class='col-12 mt-3'>
                        <button class='btn btn-dark d-flex' onClick={handlerAddPremio}><AddIcon className='me-1'/>Adicionar</button>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                {addPremio && addPremio.length > 0 && <Button className='rh-btn-orange d-flex' onClick={handlerSave}><SaveIcon className='me-2'/>Salvar novas premiações</Button>}
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            <LoadingSplash active={loading} absolute/>

        </Modal>
    );
}


export default ModalRifaRanking;
