import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { api } from '../../../apis/connect';
import Toast from '../../toast/Toast';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

function ModalCortesia(props) {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);
    
    const [cliente, setCliente] = useState({});
    const [rifas, setRifas] = useState([]);
    const [selectedRifa, setSelectedRifa] = useState(false);
    const [quantidade, setQuantidade] = useState(1);
    const [motivo, setMotivo] = useState('');

    useEffect(() => {

        if (props.cliente) {
            setCliente(props.cliente);
        }

        usuarioLogado?.id && api.rifas.getByEmpresa(usuarioLogado?.id).then(response => {
            
            if (response.error) {
                return;
            }

            setRifas(response);
            setSelectedRifa(response[0].id);

        });

    }, [props.cliente, usuarioLogado]);

    function handlerSave(){

        if (!selectedRifa) {
            Toast.warning('Selecione uma rifa!');
            return;
        }

        if (!quantidade) {
            Toast.warning('Informe a quantidade de números!');
            return;
        }

        if (quantidade < 1) {
            Toast.warning('A quantidade de números deve ser maior que 0!');
            return;
        }

        if (!motivo) {
            Toast.warning('Informe o motivo!');
            return;
        }

        setLoading(true);

        api.pagamentos.cortesia(cliente, selectedRifa, quantidade, motivo).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            Toast.success('Números adicionados com sucesso!');
            setLoading(false);

        });

    }

    return (

        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Adicionar números
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>

                        <div class='row mb-3'>

                            {!props?.rifa && <div className="row mt-2 mb-3">
                                <div className="col">
                                    <label>Rifas:</label>
                                    <select className="form-control cursor" onChange={e => setSelectedRifa(e.target.value)}>
                                        {rifas && rifas.map(rifa => <option value={rifa.id}>{rifa.nome}</option>)}
                                    </select>
                                </div>
                            </div>}

                            <div className='col-12 mb-3'>
                                <div class='ms-1 mb-1'>Quantidade de números:</div>
                                <input type='number' className='form-control' min={1} value={quantidade} name='quantidade' onChange={(e) => setQuantidade(e.target.value)}></input>
                            </div>

                            <div className='col-12'>
                                <div class='ms-1 mb-1'>Motivo:</div>
                                <input type='text' className='form-control' value={motivo} name='motivo' onChange={(e) => setMotivo(e.target.value)}></input>
                            </div>

                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-success' onClick={handlerSave} disabled={loading}>Adicionar</Button>
                    <Button className='btn-dark' onClick={props.onHide}>Cancelar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute={true} text='Adicionando números...' />

            </Modal>

        </>

    );
}


export default ModalCortesia;
