import React, {useState, useRef, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { EmailOutlined, Instagram, WhatsApp, AttachMoney, RedeemOutlined, ArrowBackIos, Add, Remove, SaveOutlined, BlockOutlined, CheckOutlined } from '@mui/icons-material';

import { mask_cpf, mask_money, mask_phone } from '../../utils/masks';

import { api } from '../../apis/connect';
import Toast from '../../components/toast/Toast';

import ModalDefault from '../../components/modals/modalDefault/ModalDefault';
import TableExtrato from '../../components/tableExtrato/TableExtrato';
import ModalCortesia from '../../components/modals/modalCortesia/ModalCortesia';

import LoadingSplash from '../../components/LoadingSplash/LoadingSplash';

function DetalhesCliente(props) {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    
    const [cliente, setCliente] = useState({});
    const [modify, setModify] = useState(false);

    const [modalExtrato, setModalExtrato] = useState(false);
    const [modalCortesia, setModalCortesia] = useState(false);

    useEffect(() => {

        setModify(false);

        setLoading(true);

        id && api.cliente.get(id).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setCliente(response);
            setLoading(false);

        });

    }, [id]);

    function handlerOnChange(e){
        setCliente({...cliente, [e.target.name]: e.target.value});
        setModify(true);
    }

    function handlerSave(){

        setLoading(true);

        api.cliente.update(cliente).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            Toast.success('Cliente atualizado com sucesso!');
            setLoading(false);

        });

    }

    return (

        <div className='container mt-4'>

            {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

            <div class='mb-4'>
                <h3>Cliente #{cliente?.id}</h3>
                <p>Veja as informações do cliente</p>
            </div>

            <div class="mb-4">

                <div class='row mb-3'>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Nome:</div>
                        <input type='text' className='form-control' value={cliente.nome} name='nome' onChange={handlerOnChange} readOnly={props.readonly}></input>
                    </div>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>CPF:</div>
                        <input type='text' className='form-control' value={mask_cpf(cliente.cpf)} name='cpf' onChange={handlerOnChange} readOnly={props.readonly}></input>
                    </div>

                </div>

                <div class='row mb-3'>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Celular:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={mask_phone(cliente.celular)} name='celular' onChange={handlerOnChange} readOnly={props.readonly}></input>
                            <Link target='_blank' to={`https://api.whatsapp.com/send/?phone=55${cliente.celular}`} className='btn btn-success ms-2' title='Enviar mensagem'><WhatsApp/></Link>
                        </div>
                    </div>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Email:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={cliente.email} name='email' onChange={handlerOnChange} readOnly={props.readonly}></input>
                            <Link target='_blank' to={`mailto:${cliente.email}`} className='btn btn-danger ms-2' title='Enviar e-mail'><EmailOutlined/></Link>
                        </div>
                    </div>

                </div>

                <div class='row mb-3 justify-content-center'>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Instagram:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={cliente.instagram} name='instagram' onChange={handlerOnChange} readOnly={props.readonly}></input>
                            <Link target='_blank' to={`https://instagram.com/${cliente.instagram}`} className='btn insta-btn ms-2' title='Ver perfil'><Instagram/></Link>
                        </div>
                    </div>

                </div>

                <div class='row mb-2'>

                    <div>Ações:</div>

                    <div class='d-flex mt-2'>
                        <Button className='btn-dark d-flex m-2' onClick={() => {setModalExtrato(true)}}><AttachMoney className='me-2'/>Extrato</Button>
                        <Button className='btn-dark d-flex m-2' onClick={() => setModalCortesia(true)}><RedeemOutlined className='me-2'/>Cortesia</Button>
                    </div>

                </div>

            </div>

            <div class="d-flex justify-content-end">
                {modify && <Button className='btn-success d-flex' onClick={handlerSave} disabled={loading}><SaveOutlined className='me-2'/>Salvar</Button>}
            </div>


            <ModalDefault show={modalExtrato} onHide={() => setModalExtrato(false)} title={`Extrato do cliente - ${cliente.nome}`} children={<TableExtrato cliente={cliente.id} inicio={cliente.created_at} />} />
            <ModalCortesia show={modalCortesia} onHide={() => setModalCortesia(false)} cliente={cliente} />

            <LoadingSplash active={loading}/>

        </div>

    );
}


export default DetalhesCliente;
