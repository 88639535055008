import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import '../assets/css/home.css'

import LoadingSplash from '../components/LoadingSplash/LoadingSplash';
import { api } from '../apis/connect';
import { useAuth } from '../contexts/AuthContext';
import TableExtrato from '../components/tableExtrato/TableExtrato';
import { ArrowBackIos, Money } from '@mui/icons-material';
import Toast from '../components/toast/Toast';
import { mask_money } from '../utils/masks';

function Home() {

    const { usuarioLogado } = useAuth();

    const [relatorio, setRelatorio] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setLoading(true);

        usuarioLogado?.id && api.relatorios.empresa(usuarioLogado.id).then(response => {

            if (response.error) {
                Toast.error(response.message);
                setLoading(false);
                return;
            }

            setRelatorio(response);
            setLoading(false);

        })

    }, [usuarioLogado])

    return(
        <div className='container mt-4'>

            {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

            <div>
                <h3>Dashboard</h3>
                <p>Aqui está o resumo completo do seu negócio!</p>
            </div>

            <section id='metricas' class='d-flex justify-content-center my-5 flex-wrap'>

                <div class="card m-2 px-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Hoje</span>
                                <span class="h5 font-bold mb-0">{mask_money(relatorio?.vendas?.hoje?.total)}</span>
                            </div>
                        </div>
                        {/* <div class="mt-2 mb-0 text-sm">
                            <span class="badge badge-pill bg-soft-success text-success me-2">
                                <i class="bi bi-arrow-up me-1"></i>{((relatorio?.vendas?.hoje?.total - relatorio?.vendas?.ontem?.total)/relatorio?.vendas?.ontem?.total)*100}%
                            </span>
                            <span class="text-nowrap text-xs text-muted">Since last month</span>
                        </div> */}
                        <span class="text-nowrap text-xs text-muted">{relatorio?.vendas?.hoje?.quantidade} vendas</span>
                    </div>
                </div>

                <div class="card m-2 px-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Semana</span>
                                <span class="h5 font-bold mb-0">{mask_money(relatorio?.vendas?.semana?.total)}</span>
                            </div>
                        </div>
                        <span class="text-nowrap text-xs text-muted">{relatorio?.vendas?.semana?.quantidade} vendas</span>
                    </div>
                </div>

                <div class="card m-2 px-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Mês</span>
                                <span class="h5 font-bold mb-0">{mask_money(relatorio?.vendas?.mes?.total)}</span>
                            </div>
                        </div>
                        <span class="text-nowrap text-xs text-muted">{relatorio?.vendas?.mes?.quantidade} vendas</span>
                    </div>
                </div>

                <div class="card m-2 px-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Ano</span>
                                <span class="h5 font-bold mb-0">{mask_money(relatorio?.vendas?.ano?.total)}</span>
                            </div>
                        </div>
                        <span class="text-nowrap text-xs text-muted">{relatorio?.vendas?.ano?.quantidade} vendas</span>
                    </div>
                </div>

                <div class="card m-2 px-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Clientes</span>
                                <span class="h5 font-bold mb-0">{relatorio?.clientes?.total}</span>
                            </div>
                        </div>
                        <span class="text-nowrap text-xs text-muted" title={relatorio?.clientes?.inativos + ' inativos'}>{relatorio?.clientes?.ativos} ativos</span>
                    </div>
                </div>

                <div class="card m-2 px-3">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Afiliados</span>
                                <span class="h5 font-bold mb-0">{relatorio?.afiliados?.quantidade}</span>
                            </div>
                        </div>
                        <span class="text-nowrap text-xs text-muted" title='Saldo total de afiliados'>{mask_money(relatorio?.afiliados?.saldo ?? 0)}</span>
                    </div>
                </div>

                <div class="card m-2 px-3 ultimo">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Parceiros</span>
                                <span class="h5 font-bold mb-0">{relatorio?.parceiros?.quantidade}</span>
                            </div>
                        </div>
                        <span class="text-nowrap text-xs text-muted">Empresa(s)</span>
                    </div>
                </div>

            </section>

            <section id='extrato'>

                <div class='card'>
                    <div class='card-header'>
                        <h5>Extrato</h5>
                    </div>
                    <div class='card-body'>
                        <TableExtrato/>
                    </div>
                </div>

            </section>

            <LoadingSplash active={loading}/>

        </div>
    )
}

export default Home