import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { api } from '../../../apis/connect';
import Toast from '../../toast/Toast';
import { useAuth } from '../../../contexts/AuthContext';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

function ModalConfigAfiliados(props) {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);
    
    const [porcentagem, setPorcentagem] = useState(false);

    useEffect(() => {

        usuarioLogado?.id && api.empresa.get(usuarioLogado?.id).then(response => {
            
            if (response.error) {
                return;
            }

            setPorcentagem(response.afiliado_porcentagem);

        });

    }, [usuarioLogado]);

    function handlerSave(){

        if (!porcentagem) {
            Toast.error('Informe a porcentagem');
            return;
        }

        if (porcentagem < 1) {
            Toast.error('Porcentagem inválida');
            return;
        }

        setLoading(true);

        api.empresa.updateAfiliadoPorcentagem(porcentagem).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            Toast.success('Porcentagem atualizada com sucesso');
            setLoading(false);
            props.onHide();

        });

    }

    return (

        <>
            <Modal
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Configuração de afiliados
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div>

                        <div class='row mb-3'>

                            <div className='col-12 mb-3'>
                                <div class='ms-1 mb-1'>Porcentagem padrão para novos afiliados:</div>
                                <div class='input-group w-50'>
                                    <input aria-describedby='extra' type='number' className='form-control' min={1} value={porcentagem} name='porcentagem' onChange={(e) => setPorcentagem(e.target.value)}></input>
                                    <span class="input-group-text" id="extra">%</span>
                                </div>
                            </div>

                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-success' onClick={handlerSave} disabled={loading}>Alterar</Button>
                    <Button className='btn-dark' onClick={props.onHide}>Cancelar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute={true} text='Alterando...' />

            </Modal>

        </>

    );
}


export default ModalConfigAfiliados;
