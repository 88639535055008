import React, {useState, useRef, useEffect} from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { Search, InfoOutlined, EmojiEventsOutlined, AdsClickOutlined, WhatsApp, EmailOutlined, Instagram, AttachMoney } from '@mui/icons-material';

import LoadingSplash from '../../LoadingSplash/LoadingSplash';
import Toast from '../../toast/Toast';

import { api } from '../../../apis/connect';
import { Link } from 'react-router-dom';

import ModalDefault from '../modalDefault/ModalDefault';
import TableExtrato from '../../tableExtrato/TableExtrato';

function ModalResultados(props) {

    const [loading, setLoading] = useState(false);
    const [loadingVencedor, setLoadingVencedor] = useState(false);

    const [rifa, setRifa] = useState({});
    const [data, setData] = useState([]);
    const [dataVencedor, setDataVencedor] = useState({});

    const [concurso, setConcurso] = useState('');

    const [ inputConcurso, setInputConcurso ] = useState('');
    const [mode, setMode] = useState(false);
    const [notNull, setNotNull] = useState(false);

    const [regra, setRegra] = useState('sequencia_horizontal');
    const [numero, setNumero] = useState('');
    const [textEspera, setTextEspera] = useState('Preencha os campos e verifique o ganhador');

    const [modalExtrato, setModalExtrato] = useState(false);

    useEffect(() => {

        setLoading(true);

        setRifa(props.rifa);

        props.rifa.id && api.rifas.resultados.loteriafederal(concurso).then(response => {

            if (response.error) {
                Toast.error(response.error);
                setLoading(false);
                return;
            }

            setData(response);
            setInputConcurso(response.concurso);

            setLoading(false);

        });

    }, [props.rifa.id, concurso]);

    let handlerVencedor = () => {

        if (loadingVencedor) return;
        
        if (mode) {
            
            setLoadingVencedor(true);

            api.rifas.resultados.vencedor(props.rifa.id, regra, concurso).then(response => {

                if (response.error) {
                    Toast.error(response.error);
                    setTextEspera(response.error);
                    setLoadingVencedor(false);
                    return;
                }
    
                Toast.success('Vencedor encontrado!');
                setDataVencedor(response);
                setLoadingVencedor(false);
    
            });

        } else {
                
            setLoadingVencedor(true);

            if (!notNull) {

                api.rifas.getNumero(props.rifa.id, numero).then(response => {
    
                    if (response.error) {
                        Toast.error(response.error);
                        setTextEspera(response.error);
                        setLoadingVencedor(false);
                        return;
                    }
    
                    if (response.cliente) {
                        Toast.success('Vencedor encontrado!');
                        setDataVencedor({
                            vencedor: response,
                            numero_da_sorte: numero
                        });
                    } else {
                        Toast.error('Número não comprado');
                        setTextEspera('Número não comprado');
                    }
    
                    setLoadingVencedor(false);
        
                });

            } else {

                api.rifas.resultados.search1R(props.rifa.id, numero).then(response => {
    
                    if (response.error) {
                        Toast.error(response.error);
                        setTextEspera(response.error);
                        setLoadingVencedor(false);
                        return;
                    }
                    Toast.success('Vencedor encontrado!');
                    setDataVencedor(response);
    
                    setLoadingVencedor(false);
        
                });

            }

        }
    
    }

    return (
        <>

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Rifa #{props.rifa.id} - Resultados da Loteria Federal
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div class='row justify-content-center mt-4'>

                        <div class='col-6 col-sm-5 d-flex justify-content-center align-items-center'>
                            <label class='me-4'>Dia:</label>
                            <input type='text' class='form-control' value={data.data} readOnly/>
                        </div>

                        <div class='col-6 col-sm-5 d-flex justify-content-center align-items-center'>
                            <label class='me-4'>Concurso:</label>
                            <input type='number' class='form-control' min='1' value={inputConcurso} onChange={(e) => setInputConcurso(e.target.value)}/>
                            <button class='btn btn-dark ms-2' onClick={() => setConcurso(inputConcurso)}><Search/></button>
                        </div>

                    </div>

                    <div class='ms-2 mb-4 mt-5 text-center fs-5'>Resultados:</div>
                    <div class='table-responsive'>
                            
                        <table class='table table-striped table-hover table-bordered table-lg'>

                            <thead>
                                <tr class='text-center'>
                                    <th>Prêmio</th>
                                    <th colSpan={6}>Bilhete</th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.resultado?.map((resultado, index) => (
                                    <tr class='text-center' key={index}>
                                        <td>{index+1}º</td>
                                        <td>{resultado[0]}</td>
                                        <td>{resultado[1]}</td>
                                        <td>{resultado[2]}</td>
                                        <td>{resultado[3]}</td>
                                        <td>{resultado[4]}</td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>

                    </div>

                    <div>
                        <Link target='_blank' to={data.site} className='d-inline-flex small text-decoration-none'>
                            <AdsClickOutlined className='me-2' fontSize='small'/>
                            Clique para ver os resultados no site oficial da Loteria Federal
                        </Link>
                    </div>

                    <div className='d-flex justify-content-between mb-5 mt-5'>
                        <div class='fs-5'>Aplicando a rifa:</div>
                        <div class='d-flex align-items-center'>
                            <label class='me-3'>Automático:</label>
                            <div class='form-check form-switch'>
                                <input type="checkbox" role="switch" class='form-check-input' checked={mode} onChange={(e) => setMode(e.target.checked)}/>
                            </div>
                        </div>
                    </div>

                    { mode && <div class='row'>

                        <div class='col-6 d-flex justify-content-center align-items-center'>
                            <label class='ms-3 me-3'>Regra:</label>
                            <select class='form-select' onChange={(e) => setRegra(e.target.value)}>
                                <option value='sequencia_horizontal'>Sequência horizontal</option>
                                <option value='sequencia_vertical'>Sequência vertical</option>
                                <option value='1L'>1º Prêmio + Lateral</option>
                                <option value='1R'>1º Prêmio + Not null</option>
                                <option value='cobrinha'>Cobrinha</option>
                                <option value='cobrinha_reverse'>Cobrinha ao contrário</option>
                            </select>
                        </div>

                        <div class='col-6 d-flex align-items-center'>
                            <button class='btn btn-dark me-2 d-flex' title="Como funciona essa regra?"><InfoOutlined/></button>
                            <button class='btn btn-success d-flex' onClick={handlerVencedor}><EmojiEventsOutlined className='me-2'/>Verificar ganhador</button>
                        </div>

                    </div>}

                    { !mode && <div class='row'>

                        <div class='col-6 d-flex justify-content-center align-items-center'>
                            <label class='ms-3 me-3'>Número:</label>
                            <input type='number' class='form-control' min='1' max={props.rifa.sonho ? props.rifa.sonho : props.rifa.quant} placeholder='0000000' value={numero} onChange={(e) => setNumero(e.target.value)}/>
                        </div>

                        <div class='col-6 d-flex align-items-center'>
                            <button class='btn btn-dark d-flex' onClick={handlerVencedor}><Search className='me-2'/>Pesquisar número</button>
                        </div>

                        {props.rifa.empresas_id === 1 && <div class='d-flex align-items-center mt-2 ms-3'>
                            {/* <label class='mx-3'>Not null:</label> */}
                            <div class='form-check form-switch'>
                                <input type="checkbox" role="switch" class='form-check-input' checked={notNull} onChange={(e) => setNotNull(e.target.checked)}/>
                            </div>
                        </div>}

                    </div>}

                    <div class='position-relative mt-5 mb-3 py-3'>
                        
                        {dataVencedor?.vencedor ? <>
                        
                        <div class='fs-5 mb-3 text-center'>Vencedor:</div>

                        <div class='row justify-content-center'>

                            <div class='col-5 text-center'>
                                <label class='mb-2'>Número da sorte:</label>
                                <input type='text' class='form-control text-center' value={dataVencedor.numero_da_sorte} readOnly/>
                            </div>

                            <div class='col-5 text-center'>
                                <label class='mb-2'>Nome:</label>
                                <input type='text' class='form-control text-center' value={dataVencedor.vencedor.cliente?.nome} readOnly/>
                            </div>

                            <div class='col-12 mt-4 d-flex justify-content-center'>
                                <Link target='_blank' to={`https://api.whatsapp.com/send?phone=55${dataVencedor.vencedor.cliente.celular}`} class='btn btn-success d-inline-flex m-2'><WhatsApp className='me-2'/>Whatsapp</Link>
                                {dataVencedor.vencedor.cliente.instagram && <Link to={`https://www.instagram.com/${dataVencedor.vencedor.cliente.instagram}`} target='_blank' class='btn insta-btn d-inline-flex m-2'><Instagram className='me-2'/>Instagram</Link>}
                                {dataVencedor.vencedor.cliente.email && <Link to={`mailto:${dataVencedor.vencedor.cliente.email}`} class='btn btn-danger d-inline-flex m-2'><EmailOutlined className='me-2'/>Email</Link>}
                                <button class='btn btn-dark d-inline-flex m-2' onClick={() => {setModalExtrato(dataVencedor.vencedor.cliente.id)}}><AttachMoney className='me-2'/>Extrato</button>
                            </div>

                        
                        </div>

                        </>
                        
                        :

                        <>

                        <div class='fs-5 m-3 mt-5 text-center'>{textEspera}</div>

                        </>}
                        
                        <LoadingSplash active={loadingVencedor} absolute/>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <Button className='btn-danger' onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute/>

            </Modal>

            <ModalDefault show={modalExtrato ? true : false} onHide={() => setModalExtrato(false)} title='Extrato do cliente' children={<TableExtrato cliente={modalExtrato} rifa={rifa.id} inicio={rifa.created_at} />}/>

        </>
    );
}


export default ModalResultados;
