import {Link} from 'react-router-dom';

import './footer.css';

function Footer() {
    return(
        <footer>
            <div class='made'>Created by <Link to='https://rifahub.com.br' target='_blank'>RifaHub</Link></div>
            <div class='links'>
                <Link to='/termos-e-politica'>Termos de Uso | Política de Privacidade</Link>
            </div>
        </footer>
    )
}

export default Footer