import React, {useState, useRef, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { EmailOutlined, Instagram, WhatsApp, AttachMoney, RedeemOutlined, ArrowBackIos, Add, Remove, SaveOutlined, BlockOutlined, CheckOutlined, EditOutlined, Password } from '@mui/icons-material';

import { mask_cpf, mask_money, mask_phone } from '../../utils/masks';

import { api } from '../../apis/connect';
import Toast from '../../components/toast/Toast';

import ModalDefault from '../../components/modals/modalDefault/ModalDefault';
import TableExtrato from '../../components/tableExtrato/TableExtrato';
import ModalCortesia from '../../components/modals/modalCortesia/ModalCortesia';

import LoadingSplash from '../../components/LoadingSplash/LoadingSplash';
import Swal from 'sweetalert2';

function DetalhesAfiliado(props) {

    const {id} = useParams();

    const [loading, setLoading] = useState(false);
    const [replay, setReplay] = useState(0);
    
    const [afiliado, setAfiliado] = useState({});
    const [modify, setModify] = useState(false);

    const [modalExtrato, setModalExtrato] = useState(false);
    const [modalCortesia, setModalCortesia] = useState(false);

    useEffect(() => {

        setModify(false);

        setLoading(true);

        id && api.afiliados.get(id).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setAfiliado(response);
            setLoading(false);

        });

    }, [id, replay]);

    function handlerOnChange(e){
        setAfiliado({...afiliado, [e.target.name]: e.target.value});
        setModify(true);
    }

    function handlerSave(){

        setLoading(true);

        api.afiliados.update(afiliado).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            Toast.success('Afiliado atualizado com sucesso!');
            setLoading(false);

        });

    }

    function handlerBloqueio(){

        setLoading(true);

        api.afiliados.block(afiliado.id, afiliado?.bloqueado ? false : true).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            Toast.success(`Afiliado ${afiliado?.bloqueado ? 'desbloqueado' : 'bloqueado'} com sucesso!`);
            setLoading(false);
            setReplay(replay + 1);

        });

    }

    function handlerPorcentagem(){

        Swal.fire({
            title: 'Alterar comissão por venda',
            input: 'number',
            inputLabel: 'Nova porcentagem:',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Alterar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            // cor verde 
            confirmButtonColor: '#198754',
            preConfirm: (porcentagem) => {

                return api.afiliados.updatePorcentagem(afiliado.id, porcentagem).then(response => {

                    if (response.error) {
                        Swal.showValidationMessage(response.error);
                        return;
                    }
        
                });

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Toast.success('Porcentagem alterada com sucesso!');
                setReplay(replay + 1);
            }
        })

    }

    function handlerNewPassword(){

        Swal.fire({
            title: 'Alterar senha do afiliado',
            input: 'password',
            inputLabel: 'Nova senha:',
            inputAttributes: {
                autocapitalize: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Alterar',
            cancelButtonText: 'Cancelar',
            showLoaderOnConfirm: true,
            // cor verde 
            confirmButtonColor: '#198754',
            preConfirm: (senha) => {

                return api.afiliados.updatePassword(afiliado.id, senha).then(response => {

                    if (response.error) {
                        // Toast.error(response.error);
                        Swal.showValidationMessage('Algo deu errado! Tente novamente com outra senha!');
                        return;
                    }
        
                });

            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if (result.isConfirmed) {
                Toast.success('Senha alterada com sucesso!');
                setReplay(replay + 1);
            }
        })

    }

    return (

        <div className='container mt-4'>

            {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

            <div class='mb-4'>
                <h3>Afiliado #{afiliado?.id}</h3>
                <p>Veja as informações do afiliado</p>
            </div>

            <div class="mb-4">

                <div class='row mb-3'>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Nome:</div>
                        <input type='text' className='form-control' value={afiliado.nome} name='nome' onChange={handlerOnChange} readOnly={props.readonly}></input>
                    </div>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>CPF:</div>
                        <input type='text' className='form-control' value={mask_cpf(afiliado.cpf)} readOnly disabled></input>
                    </div>

                </div>

                <div class='row mb-3'>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Celular:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={mask_phone(afiliado.telefone)} name='telefone' onChange={handlerOnChange} readOnly={props.readonly}></input>
                            <Link target='_blank' to={`https://api.whatsapp.com/send/?phone=55${afiliado.telefone}`} className='btn btn-success ms-2' title='Enviar mensagem'><WhatsApp/></Link>
                        </div>
                    </div>

                    <div className='col-6'>
                        <div class='ms-1 mb-1'>Email:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={afiliado.email} readOnly disabled></input>
                            <Link target='_blank' to={`mailto:${afiliado.email}`} className='btn btn-danger ms-2' title='Enviar e-mail'><EmailOutlined/></Link>
                        </div>
                    </div>

                </div>

                <div class='row mb-3 justify-content-center'>
                    
                    <div className='col-3'>
                        <div class='ms-1 mb-1'>Saldo:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={mask_money(afiliado.saldo)} readOnly disabled></input>
                            {/* <button className='btn btn-dark ms-2' title='Adicionar saldo'><Add fontSize='small'/></button>
                            <button className='btn btn-dark ms-2' title='Remover saldo'><Remove fontSize='small'/></button> */}
                        </div>
                    </div>

                    <div className='col-3'>
                        <div class='ms-1 mb-1'>Porcentagem por venda:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={`${afiliado.porcentagem}%`} readOnly disabled></input>
                            <button className='btn btn-dark ms-2' title='Editar porcentagem' onClick={handlerPorcentagem}><EditOutlined fontSize='small'/></button>
                        </div>
                    </div>

                    <div className='col-3'>
                        <div class='ms-1 mb-1'>Data de criação:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={new Date(afiliado.created_at).toLocaleString()} readOnly disabled></input>
                        </div>
                    </div>

                    <div className='col-3'>
                        <div class='ms-1 mb-1'>Código de afiliado:</div>
                        <div class='d-flex align-items-center'>
                            <input type='text' className='form-control' value={afiliado.codigo} readOnly disabled></input>
                        </div>
                    </div>

                </div>

                <div class='row mb-2 mt-4'>

                    <div>Ações:</div>
                    
                    <div class='d-flex mt-2'>
                        {/* <Button className='btn-dark d-flex m-2' onClick={() => {setModalExtrato(true)}}><AttachMoney className='me-2'/>Extrato</Button> */}
                        <Button className='btn-dark d-flex m-2' onClick={handlerBloqueio}>
                            {afiliado?.bloqueado ? <><CheckOutlined className='me-2'/>Desbloquear</> : <><BlockOutlined className='me-2'/>Bloquear</>}
                        </Button>
                        <Button className='btn-dark d-flex m-2' onClick={handlerNewPassword}><Password className='me-2'/>Alterar senha</Button>
                    </div>

                </div>

            </div>

            <div class="d-flex justify-content-end">
                {modify && <Button className='btn-success d-flex' onClick={handlerSave} disabled={loading}><SaveOutlined className='me-2'/>Salvar</Button>}
            </div>


            <ModalDefault show={modalExtrato} onHide={() => setModalExtrato(false)} title={`Extrato do cliente - ${afiliado.nome}`} children={<TableExtrato cliente={afiliado.id} inicio={afiliado.created_at} />} />

            <LoadingSplash active={loading}/>

        </div>

    );
}


export default DetalhesAfiliado;
