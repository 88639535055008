import React, {useState, useRef, useEffect} from 'react';

import './modalRifaImagens.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalImage from 'react-modal-image';

import Toast from '../../toast/Toast';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';

import { api, url_storage } from '../../../apis/connect';
import LoadingSplash from '../../LoadingSplash/LoadingSplash';

function ModalRifaImagens(props) {

    const [replay, setReplay] = useState(0);

    const formImagem = useRef(null);

    const [fotos, setFotos] = useState([]);

    const [loading, setLoading] = useState(true);

    useEffect(() => {

        setLoading(true);

        props?.rifa?.id && api.rifas.imagens.get(props?.rifa?.id).then(response => {

            if (response.error) {
                setLoading(false);
                setFotos([]);
                return;
            }

            setFotos(response);

            setLoading(false);

        });

    }, [props?.rifa?.id, replay]);

    let handlerAddFoto = () => {

        let formData = new FormData(formImagem.current);

        if (!formData.get('foto')) {
            Toast.warning('Você precisa selecionar uma imagem');
            return;
        }

        setLoading(true);

        api.rifas.imagens.add(formData).then(response => {

            document.querySelector('#preview').classList.add('d-none');

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            Toast.success('Imagem adicionada com sucesso');

            setReplay(replay+1);

            setLoading(false);

        });

    }

    let handlerRemove = (foto) => {
            
            setLoading(true);
    
            api.rifas.imagens.remove(foto).then(response => {
    
                if (response.error) {
                    Toast.error(response.error);
                    return;
                }
    
                Toast.success('Imagem removida com sucesso');
    
                setReplay(replay+1);
    
                setLoading(false);
    
            });
    }

    return (


        <Modal
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Rifa #{props?.rifa?.id} - Imagens
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <h5 class='d-block'>Imagens ativas:</h5>
                <div class='d-flex justify-content-around my-4 flex-wrap'>

                    {fotos && fotos.length > 0 ? fotos.map((foto, index) => (
                        <div key={index} className='position-relative rh-numbers rh-gallery m-2'>
                            <ModalImage 
                                small={url_storage+'/'+foto.foto} 
                                large={url_storage+'/'+foto.foto} 
                                medium={url_storage+'/'+foto.foto} 
                                alt={`${props.rifa.nome} - Foto #${foto.id}`}
                                className='img-fluid'
                                showRotate={true}
                            />
                            <div class='rh-btn-remove d-none' onClick={() => handlerRemove(foto.id)}><ClearIcon/></div>
                        </div>
                    )) : 'Nenhuma imagem cadastrada'}

                </div>

                <h5 class='d-block my-3'>Adicionar nova imagem:</h5>

                <div class='row'>

                    <form class='col-12 mb-3' encType='multipart/form-data' ref={formImagem}>
                        <label class='d-block mb-2'>Selecione um arquivo (png, jpg, jpeg):</label>
                        <input type='file' class='form-control' name='foto' onChange={(e) => {

                            let preview = document.querySelector('#preview img');
                            let file = e.target.files[0];
                            let reader = new FileReader();
                            reader.onloadend = function () {
                                preview.src = reader.result;
                            }
                            reader.readAsDataURL(file);

                            document.querySelector('#preview').classList.remove('d-none');

                        }}/>
                        <input type='hidden' name='rifa' value={props?.rifa?.id}/>
                    </form>

                    <div class='col-12 mb-2 d-none' id='preview'>
                        <div>Preview: </div>
                        <div class='d-flex justify-content-center my-2'>
                            <img src='' class='img-fluid' alt='preview'/>
                        </div>
                    </div>

                    <div class='col-12'>
                        <button class='btn btn-dark d-flex' onClick={handlerAddFoto}><AddIcon className='me-1'/>Adicionar</button>
                    </div>

                </div>

            </Modal.Body>
            <Modal.Footer>
                <Button className='btn-dark' onClick={props.onHide}>Fechar</Button>
            </Modal.Footer>

            <LoadingSplash active={loading} absolute/>

        </Modal>
    );
}


export default ModalRifaImagens;
