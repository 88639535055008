import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import './sidebar.css';

import { Home, Storefront, LocalActivityOutlined, PeopleAltOutlined, Groups } from '@mui/icons-material';

import SemLogo from '../../assets/imgs/menu/semlogo.png';
import LogoRifahit from '../../assets/imgs/logos/3.png';

import { useAuth } from '../../contexts/AuthContext';
import { api, url_storage } from '../../apis/connect';
import md5 from 'md5';

function MenuLogin(){
    const [imagem, setImagem] = useState(LogoRifahit);
    const [menu, setMenu] = useState(true);

    const {logout, usuarioLogado } = useAuth();

    const [links, setLinks] = useState([
        {nome: 'Home', link: '/', icon: <Home/>},
        {nome: 'Rifas', link: '/rifas', icon: <LocalActivityOutlined/>},
        {nome: 'Clientes', link: '/clientes', icon: <PeopleAltOutlined/>},
        {nome: 'Afiliados', icon: <Groups/>, list: [
            {nome: 'Gerenciar', link: '/afiliados'},
            {nome: 'Solicitações de saque', link: '/afiliados/solicitacoes-de-saque'}
        ]},
        {nome: 'Remarketing', icon: <Storefront/>, list: [
            {nome: 'Pagamentos Incompletos', link: '/remarketing/pagamentos-incompletos'},
            {nome: 'Nenhum pagamento', link: '/remarketing/cadastro-sem-pagamento'}
        ]},
    ]);

    useEffect(() => {
        handlerOnClick();
    }, [window.location.pathname]);

    function handlerOnClick(){

        const linksAux = links.map(link => {

            if (link.list) {
                link.list = link.list.map(sublink => {
                    if (window.location.pathname === sublink.link) {
                        sublink.active = true;
                    } else {
                        sublink.active = false;
                    }
                    return sublink;
                });
            }

            if (window.location.pathname === link.link) {
                link.active = true;
            } else {
                link.active = false;
            }
            return link;
        });

        setLinks(linksAux);

        hideMenuResponsive();

    }

    function hideMenuResponsive(){
        
        if (window.innerWidth <= 952) {
            
            let sidebar = document.querySelector('.rh-sidebar')
            sidebar.classList.toggle('d-flex', false);

        }

    }

    return(
        <div class="d-flex flex-column flex-shrink-0 p-3 rh-sidebar">
            <ul class="nav nav-pills flex-column mb-auto rh-nav my-4">
                {links.map((link, index) => {

                    let sublinks_name = 'sublink-'+md5(link.nome).slice(0, 5);

                    return(
                        <li class="nav-item" key={index}>
                            { link?.link ? 
                                <Link 
                                    to={link.link} 
                                    class={`nav-link d-flex justify-content-start ${link.active ? 'active' : 'text-white'}`} 
                                    aria-current="page" 
                                    onClick={handlerOnClick}
                                    >
                                        {link?.icon}<span class='ms-2'>{link.nome}</span>
                                </Link>
                                :
                                <>
                                    <Link 
                                        to={'#'+sublinks_name} 
                                        class={`nav-link d-flex justify-content-start ${link.active ? 'active' : 'text-white'}`} 
                                        data-bs-toggle="collapse" 
                                        role="button"  
                                        aria-expanded="false"
                                        aria-controls={sublinks_name}
                                        >
                                            {link?.icon}<span class='ms-2'>{link.nome}</span>
                                    </Link>
                                    <div class='collapse' id={sublinks_name}>
                                        {link.list.map((sublink, index) => {
                                            return(
                                                <Link 
                                                    to={sublink.link} 
                                                    class={`nav-link rh-nav-sub text-center ${sublink.active ? 'active' : 'text-white'}`} 
                                                    onClick={handlerOnClick}
                                                    >
                                                        {sublink.nome}
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </>
                            }
                        </li>
                    )
                })}
            </ul>
        </div>
    )
}

export default MenuLogin