import { BrowserRouter, Route, Routes, Navigate, Outlet } from 'react-router-dom';

// pages
import Login from './pages/Login.js';
import Home from './pages/Home.js';

import Rifas from './pages/Rifas.js';
import Rifa from './pages/rifa/Rifa.js';

import Clientes from './pages/clientes/Clientes.js';
import DetalhesCliente from './pages/clientes/Detalhes.js';

import Afiliados from './pages/afiliados/Afiliados.js';
import DetalhesAfiliado from './pages/afiliados/Detalhes.js';
import Saques from './pages/afiliados/Saques.js';

import Remarketing from './pages/Remarketing.js';
import UsersNotBuy from './pages/UsersNotBuy.js';

// components
import Footer from './components/footer/Footer.js';
import MenuLogin from "./components/menulogin/MenuLogin.js";
import Menu from "./components/menu/Menu.js";
import Sidebar from "./components/sidebar/Sidebar.js";

// context
import { AuthProvider, isAuthenticaded} from './contexts/AuthContext.js';

function PrivateRoute(props) {
  return isAuthenticaded() ? <>
      <Menu/>

      <div class="rh-bg-secundary">
        <div class="d-flex flex-row">
            <Sidebar/>
            <Outlet/>
        </div>
      </div>

    </> : <Navigate to='/login'/>
}

function App() {

  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>

          <Route path='/' element={<PrivateRoute/>}>
            <Route path='/' element={<Home/>}></Route>
            <Route path='/home' element={<Home/>}></Route>

            <Route path='/rifas' element={<Rifas/>}></Route>
            <Route path='/rifa/:id' element={<Rifa/>}></Route>

            <Route path='/clientes' element={<Clientes/>}></Route>
            <Route path='/clientes/detalhes/:id' element={<DetalhesCliente/>}></Route>

            <Route path='/afiliados' element={<Afiliados/>}></Route>
            <Route path='/afiliados/solicitacoes-de-saque' element={<Saques/>}></Route>
            <Route path='/afiliados/detalhes/:id' element={<DetalhesAfiliado/>}></Route>

            <Route path='/remarketing/pagamentos-incompletos' element={<Remarketing/>}></Route>
            <Route path='/remarketing/cadastro-sem-pagamento' element={<UsersNotBuy/>}></Route>
          </Route>

          <Route path='/login' element={<><MenuLogin/><Login/></>}></Route>

          <Route path='*' element={<Navigate to='/'/>}></Route>
        </Routes>
        {/* <Footer/> */}
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;