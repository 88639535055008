import React, {useState, useEffect, useRef} from 'react'
import { Link, useParams } from 'react-router-dom'

import Toast from '../../components/toast/Toast';
import { Instagram, EmailOutlined, ManageAccountsOutlined, WhatsApp, Search, ArrowBackIos } from '@mui/icons-material';

import LoadingSplash from '../../components/LoadingSplash/LoadingSplash';
import MyTable from '../../components/mytable/MyTable';

import { api } from '../../apis/connect';
import { useAuth } from '../../contexts/AuthContext';

import { mask_phone, mask_cpf } from '../../utils/masks';  

function Clientes() {

    const {usuarioLogado} = useAuth();

    const [loading, setLoading] = useState(false);

    const [data, setData] = useState([]);
    const [search, setSearch] = useState('');
    // const [inputSearch, setInputSearch] = useState('');

    const [user, setUser] = useState({});

    const [replay, setReplay] = useState(0);

    // const today = new Date();
    // var today_str = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2,'0')}-${today.getDate().toString().padStart(2,'0')}`

    // const yesterday = new Date(today);
    // yesterday.setDate(yesterday.getDate() - 1);
    // var yesterday_str = `${yesterday.getFullYear()}-${(yesterday.getMonth() + 1).toString().padStart(2,'0')}-${yesterday.getDate().toString().padStart(2,'0')}`

    // const [periodos, setPeriodos] = useState({inicio: yesterday_str, fim: today_str});

    useEffect(() => {

        setLoading(true);

        api.cliente.search(search).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setData(response);

            setLoading(false);

        })

    }, [search, replay]);

    const columns = [
        { Header: 'ID', accessor: (row) => '#'+row.id },
        { Header: 'Nome', accessor: 'nome' },
        { Header: 'Celular', accessor: (row) => mask_phone(row.celular) },
        { Header: 'Email', accessor: 'email' },
        { Header: 'CPF', accessor: (row) => mask_cpf(row.cpf)},
        { Header: 'Data de criação', accessor: (row) => new Date(row.created_at).toLocaleString() },
        { Header: 'Ações', accessor: (row) => row, Cell: ({row}) => {

            let dados = row.original;

            return (
                <>
                    <Link className='btn btn-dark m-1' title='Ver detalhes' to={`/clientes/detalhes/${dados.id}`}><ManageAccountsOutlined/></Link>
                    {/* <Link target='_blank' to={`https://api.whatsapp.com/send/?phone=55${dados.celular}`} className='btn btn-success m-1' title='Enviar mensagem'><WhatsApp/></Link>
                    {dados.instagram && <Link target='_blank' to={`https://www.instagram.com/${dados.instagram}`} className='btn m-1 insta-btn' title='Ver perfil'><Instagram/></Link>}
                    {dados.email && <Link to={`mailto:${dados.email}`} className='btn btn-danger m-1' title='Enviar e-mail'><EmailOutlined/></Link>} */}
                </>
            )
        }}

    ];

    return(
        <div className='container mt-4'>

            <section className="">

                <div class='mb-4'>

                    {window.history.length > 1 && <div className='text-secondary mb-4 d-inline-flex pe-auto small align-itens-center' onClick={() => window.history.back()}><ArrowBackIos fontSize='small' className='me-1'/><span>Voltar</span></div>}

                    <h3>Clientes</h3>
                    <p>Lista de pessoas que tem um cadastro na plataforma</p>
                </div>

                {/* <div className="row my-4">

                    <div className="col-6">
                        <label>De</label>
                        <input type='date' className="form-control" value={periodos.inicio} onChange={e => setPeriodos({...periodos, inicio: e.target.value})}></input>
                    </div>
                    <div className="col-6">
                        <label>Até</label>
                        <input type='date' className="form-control" value={periodos.fim} onChange={e => setPeriodos({...periodos, fim: e.target.value})}></input>
                    </div>

                    <div className="col-6">
                        <div class='ms-1 mb-1'>Pesquisar :</div>
                        <div class='d-flex align-itens-center'>
                            <input type='text' className="form-control" placeholder='Nome, Celular, E-mail, Instagram, CPF...' value={inputSearch} onChange={e => setInputSearch(e.target.value)}></input>
                            <button className='btn btn-dark ms-2' onClick={() => setSearch(inputSearch)}><Search/></button>
                        </div>
                    </div>

                </div> */}

                <div class='position-relative'>
                    <MyTable columns={columns} data={data} />
                    <LoadingSplash active={loading} absolute/>
                </div>

            </section>

        </div>
    )
}

export default Clientes