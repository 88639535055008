import React, {useEffect, useState, useRef} from 'react';

import './modalDetalhesRifa.css';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import InsertPhotoOutlinedIcon from '@mui/icons-material/InsertPhotoOutlined';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import MoneyIcon from '@mui/icons-material/Money';
import StarsIcon from '@mui/icons-material/Stars';
import { AttachMoney, CardGiftcard, Edit, Filter1, Link } from '@mui/icons-material';

import LoadingSplash from '../../LoadingSplash/LoadingSplash';

import { api } from '../../../apis/connect';

import TableExtrato from '../../tableExtrato/TableExtrato';

import ModalNumerosPremiados from '../modalNumerosPremiados/ModalNumerosPremiados';
import ModalRifaPromocoes from '../modalRifaPromocoes/ModalRifaPromocoes';
import ModalRifaImagens from '../modalRifaImagens/ModalRifaImagens';
import ModalRifaRanking from '../modalRifaRanking/ModalRifaRanking';
import ModalDefault from '../modalDefault/ModalDefault';
import Toast from '../../toast/Toast';
import Swal from 'sweetalert2';

function ModelDetalhesRifa(props) {

    // const [replay, setReplay] = useState(0);

    const [rifa, setRifa] = useState({});

    const [loading, setLoading] = useState(true);

    const [slug, setSlug] = useState('');

    const [showNumerosPremiados, setShowNumerosPremiados] = useState(false);
    const [showPromocoes, setShowPromocoes] = useState(false);
    // const [showGanhador, setShowGanhador] = useState(false);
    const [showImagens, setShowImagens] = useState(false);
    const [showRanking, setShowRanking] = useState(false);
    const [showExtrato, setShowExtrato] = useState(false);

    const [replay, setReplay] = useState(0);

    useEffect(() => {

        setLoading(true);

        props?.rifa && api.rifas.get(props?.rifa).then(response => {

            if (response.error) {
                Toast.error(response.error);
                return;
            }

            setRifa(response);
            setSlug(response.slug);

            setLoading(false);

        });

    }, [props, replay]);

    function handlerSlug() {

        // tem certeza que deseja alterar o link da rifa?
        Swal.fire({
            title: 'Tem certeza que deseja alterar o link da rifa?',
            text: 'Ao alterar o link da rifa, talvez tenha que alterar o link em suas redes sociais, sites e anúncios.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, alterar',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: "#198754",
            cancelButtonColor: "#000"
        }).then((result) => {
            if (result.isConfirmed) {
                
                setLoading(true);

                api.rifas.slug(rifa.id, slug).then(response => {

                    if (response.error) {
                        Toast.error(response.error);
                        setLoading(false);
                        return;
                    }

                    setReplay(replay+1);

                    setLoading(false);

                });

            }
        });

    }

    return (

        <>

            <Modal
                {...props}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Rifa #{props?.rifa} - Detalhes
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="container">

                        <div className="row">

                            <div className="col-12 mb-3">
                                <div>
                                    <b class='d-block mb-2'>Nome:</b> 
                                    <input class='form-control' readOnly value={rifa.nome}></input>
                                </div>
                            </div>

                            <div className="col-12 mb-4">
                                <div>
                                    <b class='d-block mb-2'>Descrição:</b> 
                                    <textarea readOnly class='form-control' value={rifa.describe} style={{minHeight: '200px'}}></textarea>
                                </div>
                            </div>
                            
                            <div className="col-12 mb-4">

                                <div class='d-flex justify-content-start align-items-center flex-wrap'>

                                    <button class='btn btn-dark d-flex me-4 mb-3' onClick={() => {

                                        navigator.clipboard.writeText(rifa.empresa.domain+'/rifa/'+rifa.slug);
                                        Toast.success('Link copiado para a área de transferência!');

                                    }}><Link className='me-2'/>Copiar link da rifa</button>
                                    
                                    <input class='ms-2 form-control w-50 mb-3' value={slug} onChange={(e) => setSlug(e.target.value)}></input>

                                    <button class='btn btn-dark d-flex ms-3 mb-3' title='Editar link' onClick={handlerSlug}><Edit/></button>

                                </div>

                            </div>

                            <div class='row mb-3'>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Quantidade:</b> 
                                        <input class='form-control' readOnly value={(rifa?.sonho ? rifa.sonho : rifa?.quant)?.toLocaleString('pt-br')+' de números'}></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Vendidos:</b>
                                        <input class='form-control' readOnly value={rifa?.vendidos?.toLocaleString('pt-br')+' de números'}></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Porcentagem de vendas:</b>
                                        <input class='form-control' readOnly value={rifa?.porcentagem_real?.toLocaleString('pt-br')+'%'}></input>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-3'>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Valor:</b>
                                        <input class='form-control' readOnly value={rifa?.valor?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Criada em:</b>
                                        <input class='form-control' readOnly value={new Date(rifa.created_at).toLocaleDateString()}></input>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div>
                                        <b class='d-block mb-2'>Data do sorteio:</b>
                                        <input class='form-control' readOnly value={new Date(rifa.expire_at).toLocaleDateString()}></input>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-4'>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Mínimo:</b>
                                        <input class='form-control' readOnly value={rifa.min?.toLocaleString('pt-br')}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Máximo:</b>
                                        <input class='form-control' readOnly value={rifa.max ? rifa.max?.toLocaleString('pt-br') : 'Ilimitado'}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Elástica:</b>
                                        <input class='form-control' readOnly value={rifa.elastica ? 'Sim' : 'Não'}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Fidelidade:</b>
                                        <input class='form-control' readOnly value={rifa.fidelidade ? 'Sim' : 'Não'}></input>
                                    </div>
                                </div>

                            </div>

                            <div class='row mb-2 justify-content-center'>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Ativa:</b>
                                        <input class='form-control' readOnly value={rifa.active ? 'Sim' : 'Não'}></input>
                                    </div>
                                </div>

                                <div className="col-3">
                                    <div>
                                        <b class='d-block mb-2'>Finalizada:</b>
                                        <input class='form-control' readOnly value={rifa.finalizada ? 'Sim' : 'Não'}></input>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {/* <Button className='btn-success d-flex'><EmojiEventsIcon className='me-2'/>Configurar ganhador</Button> */}
                    <Button className='btn-dark d-flex' onClick={() => {props.onHide();setShowImagens(true)}}><InsertPhotoOutlinedIcon className='me-2'/>Imagens</Button>
                    <Button className='d-flex btn-dark' onClick={() => {props.onHide();setShowNumerosPremiados(true)}}><Filter1 className='me-2'/>Números Premiados</Button>
                    <Button className='btn-dark d-flex' onClick={() => {props.onHide();setShowRanking(true)}}><StarsIcon className='me-2'/>Ranking</Button>
                    <Button className='btn-dark d-flex' onClick={() => {props.onHide();setShowPromocoes(true)}}><CardGiftcard className='me-2'/>Promoções</Button>
                    <Button className='btn-dark d-flex' onClick={() => {props.onHide();setShowExtrato(true)}}><AttachMoney className='me-2'/>Extrato</Button>
                    <Button className='btn-danger' onClick={props.onHide}>Fechar</Button>
                </Modal.Footer>

                <LoadingSplash active={loading} absolute/>

            </Modal>

            <ModalNumerosPremiados show={showNumerosPremiados} rifa={rifa} onHide={() => {setShowNumerosPremiados(false)}}/>
            <ModalRifaPromocoes show={showPromocoes} rifa={rifa} onHide={() => {setShowPromocoes(false)}}/>
            <ModalRifaImagens show={showImagens} rifa={rifa} onHide={() => {setShowImagens(false)}}/>
            <ModalRifaRanking show={showRanking} rifa={rifa} onHide={() => {setShowRanking(false)}}/>
            <ModalDefault show={showExtrato} onHide={() => {setShowExtrato(false)}} title={`Rifa #${rifa.id} - Extrato`}>
                <TableExtrato rifa={rifa.id}/>
            </ModalDefault>

        </>
    );
}


export default ModelDetalhesRifa;
